import React from 'react'
import UserData from './UserData'
import FollowAndThanksButton from './FollowAndThanksButton'
import { AES } from 'crypto-js'
import { useNavigate } from 'react-router-dom';


const ThanksForUpvoteNotification = ({ NotificationData, usertoken, loginUserid }) => {


    let navigate = useNavigate()


    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();

        if (NotificationData.receiver_id === NotificationData.photo_owner_id) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: NotificationData.photo_id,
                UserID: NotificationData.photo_owner_id,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        }

        window.scrollTo(0, 0)
    }
    return (
        <>
            <UserData NotificationData={NotificationData} message={"thanked you for your upvote :)"}>
                <div style={{ display: 'flex', gap: '24px' }}>
                    <FollowAndThanksButton
                        thanksBtnHidden={true}
                        usertoken={usertoken}
                        NotificationData={NotificationData}
                        loginUserid={loginUserid} />
                    <a className="comment-photo d-none d-sm-inline-flex" onClick={OtheruserProfileNavigateHandler}>
                        <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="d-block" />
                    </a>
                </div>



            </UserData>
        </>
    )
}

export default ThanksForUpvoteNotification