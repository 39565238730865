import React from 'react'
import UserData from './UserData';
import FollowAndThanksButton from './FollowAndThanksButton';

const FollowNotification = ({ NotificationData, usertoken, loginUserid }) => {


    return (
        <>
            {/* following back card */}
            <UserData NotificationData={NotificationData} message={"started following you"}>
                <FollowAndThanksButton
                    thanksBtnHidden={true}
                    BtnTextFollowBack={true}
                    usertoken={usertoken}
                    NotificationData={NotificationData}
                    loginUserid={loginUserid}
                />
            </UserData>

        </>
    )
}

export default FollowNotification