import React from 'react'
import UserData from './UserData'
import FollowAndThanksButton from './FollowAndThanksButton'
const ReceviceCongratulationNotification = ({ NotificationData, usertoken, loginUserid }) => {
    return (
        <>

            <UserData NotificationData={NotificationData} message={"congratulated you for making the Weekly Top Ten!"}>
                <FollowAndThanksButton
                    ThanksFor={'thanks_on_weeklytopten_congratulation'}
                    usertoken={usertoken}
                    NotificationData={NotificationData}
                    loginUserid={loginUserid}
                />
            </UserData>
            {/* <div className="profile-card style--two">
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb">
                            <a  >
                                <img src="assets/images/thumb/ac2.png" alt="profile" />
                            </a>
                        </div>
                        <div className="profile-card-content">
                            <ul className="profile-info-list">
                                <li className="name_info"><span className="title"><a   className="text-title ">Bobert Bobertson</a> </span> <span className="value font--sm text-title">congratulated you for making the Weekly Top Ten!</span></li>
                                <li className="mb-1 location_info"><span className="value font--sm ">Boston, USA</span></li>
                                <li className="d-none d-sm-block camera_info"><span className="title ">Camera: </span> <span className="value">Nicon</span></li>
                                <li className="d-none d-sm-block genres_info"><span className="title">Genres: </span> <span className="value">Street, Landscape, Art, Nature</span></li>
                            </ul>
                            <div className=" d-flex flex-wrap flex-xl-nowrap justify-content-sm-end gap-2 gap-xl-3 btn--group">
                                <button className="btn btn--light btn--116" data-normal="Send Thanks!" data-active="Sent Thanks" >Send Thanks!</button>
                                <button className="btn btn--base btn--116" data-normal="Follow" data-active="Following" >Follow</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="text-start profile-info-list d-sm-none">
                    <li className="camera_info"><span className="title text-title">Camera: </span> <span className="value">Nicon</span></li>
                    <li className="genres_info"><span className="title text-title">Genres: </span> <span className="value">Street, Landscape, Art, Nature</span></li>
                </ul>
            </div> */}

        </>
    )
}

export default ReceviceCongratulationNotification