import React from 'react'
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../utils/CropImage';
import { Slider } from '@mui/material';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import { decryptUserData } from '../../utils/encryptedData'
import Swal from 'sweetalert2';
import amplitude from 'amplitude-js'

const CoverPhotoUploadModal = () => {
    let [image, setimage] = useState('')
    let [imagePath, setimagePath] = useState('')
    let [CroppimagePath, setCroppimagePath] = useState('')
    let [ERROR, setERROR] = useState('')
    let [spinner, setspinner] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [uploaded, setUploaded] = useState('');
    const [CropperContainerHeight, setCropperContainerHeight] = useState("210px");
    const [cropSize, setCropSize] = useState({ width: 458, height: 120 });

    let dispatch = useDispatch()
    // const currentUrl = window.location.pathname;
    // <---------------- decrypted data ------------->
    const encryptedUserData = localStorage.getItem('Data');
    const decryptedUserData = decryptUserData(encryptedUserData);
    let usertoken = decryptedUserData.token
    let loginUserid = decryptedUserData.userid
    let uploadinputClickhandler = () => {
        document.querySelector('.profile-upload-input').click()

    }

    // <-------------------------------------------->
    let MeApiState = useSelector(state => state.Modal.MeApiState)
    // <-------------------------------------------------------->


    useEffect(() => {
        if (window.innerWidth <= 990) {
            setCropperContainerHeight('180px')
            setCropSize({ width: 280, height: 100 })
        }
    }, [])


    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };
    const cropImage = async () => {

        try {
            const { file, url } = await getCroppedImg(
                imagePath,
                croppedAreaPixels,
                // rotation
            );
            setCroppimagePath(url)
            setimage(file);
            dispatch(ModalAction.setCoverCropImg({
                cropImgPath: url,
                cropImg: file
            }))
            setimagePath('')
        } catch (error) {


        }
    };


    let cropandUploadHandler = async () => {
        setspinner(true)
        try {
            const timestamp = new Date().getTime();

            const { file, url } = await getCroppedImg(
                imagePath,
                croppedAreaPixels,
                // rotation
            );
            setCroppimagePath(url)
            setimage(file);
            let data = new FormData();
            data.append('photoUrl', file, `profile_image_${timestamp}.jpeg`);
            let headers = {
                'Authorization': `Bearer ${usertoken}`
            };
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/uploadCoverPhoto`, data, {
                headers,
                onUploadProgress: (data) => {
                    setUploaded(Math.round((data.loaded / data.total) * 100));
                }
            });
            amplitude.getInstance().logEvent("Cover Image Add");
            cropImage()
           
            Swal.fire(
                '',
                'Upload successfully!',
                'success'
            )
            // amplitude.getInstance().logEvent('Add Avatar Photo', { UserID: loginUserid });
            amplitude.getInstance().logEvent("Cover Image Add", { UserID: loginUserid });
            setimagePath('')
            setspinner(false)
            dispatch(ModalAction.setCoverPhotoUploadModalState(false))
        } catch (error) {

            console.log(error)
            setspinner(false)
        }
    };

    // <-------------------------------------------------------->



    const MAX_IMAGE_EDGE = 6500;
    const MAX_FILE_SIZE = 200 * 1024 * 1024; // 50 MB
    const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];



    let imgHandler = (e) => {
        setERROR("")
        let selectedImage = e.target.files[0]

        if (!ALLOWED_FORMATS.includes(selectedImage.type)) {
            setERROR('Please upload only JPG or PNG files.');
            return;
        }


        const img = new Image();
        img.src = URL.createObjectURL(selectedImage);
        img.onload = async () => {
            await img.decode();

            const { width, height } = img;


            if (width > MAX_IMAGE_EDGE || height > MAX_IMAGE_EDGE) {
                setERROR('Please ensure the edges of your photo are less than 6500 pixels.');
                return;
            } else if (selectedImage.size > MAX_FILE_SIZE) {
                setERROR('Please ensure that your file is less than 200MB.');
                return;
            } else {

                // setimage(selectedImage)

                const reader = new FileReader();
                reader.onload = () => {
                    setimagePath(reader.result);
                };
                reader.readAsDataURL(selectedImage);
            }
        }


    }
    // <-------------------------------------------->
    return (
        <>
            <div className="backdrop" />
            {/* Upload Profile */}
            <div className="modal fade bph-modal show" id="profileUploadModal" tabIndex={-1} aria-labelledby="profileUploadModal" aria-hidden="true"
                style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered modalAnimation">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="onboarding_modal photoUpload_modal profileUpload">
                                <div className="modal_inner">
                                    <button type="button" className="btn-close profile-img-modal-close_btn" data-bs-dismiss="modal" aria-label="Close"
                                        onClick={() => { dispatch(ModalAction.setCoverPhotoUploadModalState(false)) }} />
                                    {/* logo */}
                                    <div className="modal_logo d-none d-md-inline-flex">
                                        <img src="./assets/img/logo/modal-logo.png" alt="img" />
                                    </div>
                                    {/* content */}
                                    <div className="modal_content">
                                        <div className="modal_title">
                                            <h2 className="title" style={{fontSize:"35px"}}><img src="./assets/img/icons/upload.svg" alt="img" />Upload your cover image</h2>
                                        </div>
                                        {/* upload photo */}
                                        <div className="file-upload">
                                            {!imagePath && <p className="subtitle profile-upload-before-title">Add a cover image to your profile to give it your style and show Big Photo Hunters your work.</p>}
                                            {imagePath && <p className="subtitle profile-upload-after-title" style={{ display: 'block' }}>Position your image in the rectangle</p>}
                                            {/* <form action method="POST" encType="multipart/form-data"> */}

                                            {ERROR && <p className="eerror" style={{ textAlign: 'center' }}>{ERROR} </p>}

                                            {!imagePath && <div className="profile-image-upload-wrap">
                                                <input className="profile-upload-input" type="file" onChange={imgHandler} />
                                                <div className="drag-text">
                                                    <h4 className="title">Drag and drop your photo here</h4>
                                                    <span className="or">or</span>
                                                    <button className="file-upload-btn btn" type="button"
                                                        onClick={uploadinputClickhandler}
                                                    >Upload from device</button>
                                                    <span className="support_note">Max 50 megapixel, longest edge 6500 px.
                                                        JPG or PNG format only</span>
                                                </div>
                                            </div>}
                                            {imagePath &&
                                                <div>

                                                    <Cropper
                                                        image={imagePath}
                                                        crop={crop}
                                                        zoom={zoom}
                                                        rotation={rotation}
                                                        // aspect={2.5}
                                                        cropShape="rect"
                                                        onZoomChange={setZoom}
                                                        onRotationChange={setRotation}
                                                        onCropChange={setCrop}
                                                        onCropComplete={cropComplete}
                                                        cropSize={cropSize}
                                                        // restrictPosition={true}
                                                        objectFit="horizontal-cover"
                                                        style={{
                                                            containerStyle: {
                                                                height: `${CropperContainerHeight}`,
                                                                width: "100%",
                                                                // margin: "auto",
                                                                position: "relative",
                                                            },
                                                            // mediaStyle: {
                                                            //     width:'100%'
                                                            //   },
                                                            // cropAreaStyle: {
                                                            //     width: '100%',
                                                            //     height: "212px",
                                                            // },
                                                        }}
                                                    >
                                                    </Cropper>
                                                    <div className='slider_container'>
                                                        {!spinner && <Slider
                                                            // valueLabelDisplay="auto"
                                                            // valueLabelFormat={zoomPercent}
                                                            min={1}
                                                            max={3}
                                                            step={0.1}
                                                            value={zoom}
                                                            onChange={(e, zoom) => setZoom(zoom)}
                                                        />}
                                                    </div>
                                                    <div className="profile-image-title-wrap" style={{ display: 'flex', flexDirection: 'column', minWidth: "200px", margin: 'auto', alignItems: "center" }}>


                                                        {!spinner ?
                                                            <button style={{ minWidth: "200px" }} type="button" className="btn" onClick={cropandUploadHandler}>
                                                                Upload
                                                            </button>
                                                            :
                                                            <div className="progress" style={{ width: "100%", marginTop: "10px" }}>
                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${uploaded}%`, color: 'black' }} aria-valuenow={uploaded} aria-valuemin={0} aria-valuemax={100}>{`${uploaded}%`}</div>
                                                            </div>}


                                                        {!spinner && <label className="remove-profile-image" style={{ marginTop: '10px' }} onClick={() => { setimagePath("") }}>Select a different photo</label>}

                                                       
                                                    </div>
                                                
                                                </div>
                                               
                                            }
                                      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ! Upload Profile */}

        </>
    )
}

export default CoverPhotoUploadModal

// const zoomPercent = (value) => {
//   return `${Math.round(value * 100)}%`;
// };