import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const ProfileInfoSkeleton = () => {
    return (
        <>


            {/* <section className="profile-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                           
                                <div className="profile_info_inner">
                                    <div className="profile_img">
                                        <Skeleton circle height="100%" width="100%" />
                                    </div>
                                    <div className="profile_info_text">
                                        <div className="profile_name">
                                            <h4 className="name">
                                                <Skeleton width="70%" />
                                                <Skeleton />
                                            </h4>
                                        </div>

                                        <div className="connected_with d-none d-md-flex">
                                            <div className="following" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                            </div>
                                            <div className="followers" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                                <Skeleton circle height={40} width={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                        </div>
                        <div className="col-lg-6">
                            <div className="profile_details">
                                <div className="profile_details_inner">
                                    <div className="details">
                                      
                                        <div className="details_item since">
                                        <Skeleton width={70} height={16}/>
                                        <Skeleton width={100} height={16}/>
                                        </div>

                                        <div className="details_item since">
                                        <Skeleton width={70} height={16}/>
                                        <Skeleton width={100} height={16}/>
                                        </div>
                                    </div>
                                    <div className="details">
                                       
                                        <div className="details_item genres">
                                        <Skeleton width={70} height={16}/>
                                        <Skeleton width={100} height={16}/>
                                        </div>
                                    </div>
                                   
                                </div>
                               
                                <div className="connected_with d-md-none">
                                <div className="following" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                            </div>
                                    <div className="followers">
                                      
                                        <div className="following" style={{ display: 'flex', alignItems: 'center' }}>
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                                <Skeleton circle height={20} width={20} />
                                            </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="profile-area">
                <div className="profile_cover" >
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="about_profile">
                                <div className="about_profile_inner">
                                    <div className="profile_user">
                                        <Skeleton circle height="100%" width="100%" />
                                    </div>
                                    <div className="profile_details_warp">
                                        <div className="details_profile">
                                            <div className="information">
                                                <h4 className="name" >
                                                    <Skeleton width="70%" height={15} />
                                                </h4>
                                                <span className="address">
                                                    
                                                    <Skeleton width="80%" height={15} />
                                                </span>
                                                
                                            </div>
                                            <div className="following_follower">


                                                <ul className="users" >
                                                    <li>
                                                        <a style={{ cursor: 'default' }}>
                                                            <Skeleton circle height={35} width={35} />
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a style={{ cursor: 'default' }}>
                                                            <Skeleton circle height={35} width={35} />
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a style={{ cursor: 'default' }}>
                                                            <Skeleton circle height={35} width={35} />
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a style={{ cursor: 'default' }}>
                                                            <Skeleton circle height={35} width={35} />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="about_profile_desc">
                                <div className="profile_desc_text">
                                <Skeleton width="100%" height={15} />
                                <Skeleton width="50%" height={15} />
                                </div>
                                <div className="profile_system_desc">
                                    <div className="profile_system_desc_inner">
                                        <div className="system_item">
                                            <span> <Skeleton width="70%" height={15} /></span>
                                            <p><Skeleton width="80%" height={15} /></p>        
                                        </div>
                                        <div className="system_item">
                                            <span> <Skeleton width="70%" height={15} /></span>
                                            <p><Skeleton width="80%" height={15} /></p>        
                                        </div>
                                        <div className="system_item">
                                            <span> <Skeleton width="70%" height={15} /></span>
                                            <p><Skeleton width="80%" height={15} /></p>        
                                        </div>
                                        <div className="system_item" style={{marginTop:"30px"}}>
                                            <span> <Skeleton width="70%" height={15} /></span>
                                            <p><Skeleton width="80%" height={15} /></p>        
                                        </div>
                                        <div className="system_item" style={{marginTop:"30px"}}>
                                            <span> <Skeleton width="70%" height={15} /></span>
                                            <p><Skeleton width="80%" height={15} /></p>        
                                        </div>
                                        <div className="system_item" style={{marginTop:"30px"}}>
                                            <span> <Skeleton width="70%" height={15} /></span>
                                            <p><Skeleton width="80%" height={15} /></p>        
                                        </div>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ProfileInfoSkeleton