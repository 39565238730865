import React, { useState, useEffect, useRef } from 'react';
// import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
// import { arrayMove } from 'react-sortable-hoc';
import { ManageGalleryPhotos } from '../../utils/API/GalleryApi';
import { GalleryAction } from '../../Store/Gallery-Slice';
import { useDispatch } from 'react-redux';
import { loadGalleryPhotos } from '../../utils/API/GalleryApi';
import { ModalAction } from '../../Store/Modal-Slice';
import { PayWallAction } from '../../Store/PayWallSlice';


import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";

import amplitude from 'amplitude-js';

let increment = 0;
const ManageGallery = ({ usertoken, CheckDelBtnIsActiveFn, IsDeleteApiRun, setIsDeleteApiRunFalse, UserPaymentStatus }) => {
  const [Spinner, setSpinner] = useState(false)
  const [ImagesLoader, setImagesLoader] = useState(false)
  const [ManageGalleryfirstPhoto, setManageGalleryfirstPhoto] = useState([])
  const [MoreManageGalleryPhoto, setMoreManageGalleryPhoto] = useState([])
  const [items, setItems] = useState([]);
  const [IsFooter, setIsFooter] = useState(true)
  const [isHovered, setIsHovered] = useState(false);
  const [IsMobile, setIsMobile] = useState(false)
  const [SelectedPhotoIds, setSelectedPhotoIds] = useState([]);
  const [IsmoreThan5PhotosMOdal, setIsmoreThan5PhotosMOdal] = useState(false)
  const [boxesPerRow, setBoxesPerRow] = useState(5);



  // <------------------------------------->
  useEffect(() => {
    const updateBoxesPerRow = () => {
      const width = window.innerWidth;


      if (width > 1400) {
        setBoxesPerRow(5);
        setIsMobile(false)
      } else if (width > 1200) {
        setBoxesPerRow(4);
        setIsMobile(false)
      } else if (width > 767) {
        setBoxesPerRow(3);

      } else if (width > 600) {
        setBoxesPerRow(4);
        setIsMobile(true)
      }
      else if (width > 380) {
        setBoxesPerRow(3);
      }
      if (window.innerWidth <= 700) {
        setIsMobile(true)
      }
    };

    updateBoxesPerRow();
    window.addEventListener('resize', updateBoxesPerRow);

    return () => {
      window.removeEventListener('resize', updateBoxesPerRow);
    };
  }, []);



  useEffect(() => {
    if (IsDeleteApiRun) {

      const remainingItems = items.filter(item => !SelectedPhotoIds.includes(item.id));

      for (let i = 0; i < SelectedPhotoIds; i++) {
        amplitude.getInstance().logEvent("Gallery Photo Delete");
      }

      // Update state and dispatch action with the remaining items
      setItems(remainingItems);
      dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos(remainingItems));

      dispatch(GalleryAction.setSuccessfullyManageGalleryPhotos(remainingItems))

      // setSelectedPhotoIds([]); // Clear selected photo IDs
      setIsDeleteApiRunFalse()
      CheckDelBtnIsActiveFn([]);
      if (remainingItems.length == 0) {
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: true }));
        dispatch(GalleryAction.setProfileInfoPlusBtnIsClick(true));
        dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: false }));
      }
    }
  }, [IsDeleteApiRun]);

  // <------------------------------------->

  let Galleryfooterr = useRef();
  let dispatch = useDispatch()

  // <------------------------------------->

  const imgSelectHandler = (id) => {
    setSelectedPhotoIds((prevSelectedPhotoIds) => {
      const newSelectedPhotoIds = prevSelectedPhotoIds.includes(id)
        ? prevSelectedPhotoIds.filter(photoId => photoId !== id)
        : [...prevSelectedPhotoIds, id];

      CheckDelBtnIsActiveFn(newSelectedPhotoIds);
      return newSelectedPhotoIds;
    });
  };


  const grabClickHandler = (event) => {
    event.stopPropagation();
  };
  // <------------------------------------->
  // const DragHandle = SortableHandle(() => (
  //   <div className="grab" >
  //     {/* <img src="./assets/img/icons/grab.svg" alt="grab icon" /> */}
  //     <i className="bi bi-arrows-move" style={{ fontSize: '20px' }} />

  //   </div>
  // ));

  // const SortableItem = SortableElement(({ value, customIndex }) => {

  //   const itemClass = customIndex < 5 ? 'manage_photo_item active' : 'manage_photo_item';
  //   return (
  //     <div className={`${itemClass} no-select`}>
  //       <DragHandle />
  //       <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${value.compressedImageId}`} alt="gallery item"
  //       // style={{maxWidth:'200px',aspectRatio:'auto'}}
  //       />
  //     </div>
  //   );
  // });

  // const SortableList = SortableContainer(({ items }) => {
  //   return (
  //     <div className="gallery_manage_photos">
  //       {items.map((value, index) => {
  //         return (
  //           <SortableItem key={`item-${index}`} index={index} customIndex={index} value={value} />
  //         );
  //       })}
  //     </div>
  //   );
  // });



  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   setItems(arrayMove(items, oldIndex, newIndex));
  // };


  let ManageGalleryPhotosHandler = async (ArrayofId) => {

    let itemsIds = ArrayofId?.slice(0, 5)?.map(item => item.id);


    setSpinner(true)
    try {
      let response = await ManageGalleryPhotos(usertoken, itemsIds)

      dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos(ArrayofId.slice(0, 3)));
      dispatch(GalleryAction.setSuccessfullyManageGalleryPhotos(ArrayofId.slice(0, 3)));

      setSpinner(false)
    } catch (error) {
      console.log(error)
      setSpinner(false)
    }
  }





  let apiCall = (e) => {

    if (e[0].isIntersecting) {

      increment = increment + 10;

      let LoadPhotos = async function () {

        setImagesLoader(true)
        let OtherUserID = false
        try {

          const response = await loadGalleryPhotos(usertoken, OtherUserID, increment, { sortingType: 'arranged_photo', value: "new" });

          const newPhotos = response.data.data;

          if (response.data.data.length < 10) {
            setIsFooter(false)
          }
          setMoreManageGalleryPhoto([...newPhotos])


          setImagesLoader(false)


        } catch (error) {
          console.log(error)
        }
      }
      LoadPhotos();
    }
  }

  useEffect(() => {

    const Observer = new IntersectionObserver(apiCall, {
      root: null,
      threshold: 0

    })

    if (Galleryfooterr.current) Observer.observe(Galleryfooterr.current)
    return () => {
      if (Galleryfooterr.current) Observer.unobserve(Galleryfooterr.current);
    };
  }, [ManageGalleryfirstPhoto])

  useEffect(() => {

    setImagesLoader(true)


    increment = 0
    let OtherUserID = false

    let LoadManageGalleryPhotos = async function () {
      try {

        const response = await loadGalleryPhotos(usertoken, OtherUserID, increment, { sortingType: 'arranged_photo', value: "new" });
        setManageGalleryfirstPhoto(response.data.data)

        setImagesLoader(false)
        if (response.data.data.length < 10) {
          setIsFooter(false)
        }

      } catch (error) {
        console.log(error)
      }

    }
    LoadManageGalleryPhotos();
  }, [])



  useEffect(() => {
    if (increment === 0) {
      setItems([...ManageGalleryfirstPhoto]);
    } else {
      setItems(prevPhotos => [...prevPhotos, ...MoreManageGalleryPhoto])
      // setItems([...items, ...MoreManageGalleryPhoto]);
    }
  }, [ManageGalleryfirstPhoto, MoreManageGalleryPhoto]);



  function onChange(sourceId, sourceIndex, targetIndex) {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
    ManageGalleryPhotosHandler(nextState)
  }

  let PhotoUploadComponentShowHandler = () => {
    dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: true }));
    dispatch(GalleryAction.setProfileInfoPlusBtnIsClick(true));
    dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: false }));
    // dispatch(GalleryAction.setIsManageGallery(false))

  }

  let QueueModalHandler = () => {
    dispatch(ModalAction.setisPayWallOpen(true))
    dispatch(PayWallAction.setPayWallModalTitleObj({ Title: "Show the Club your photography style with up to 75 personal gallery photos" }))
  }
  return (

    <>
      {items.length > 0 && <div>
        <div className="gallery_manage">
          <h4 className="title">Manage Your Personal Gallery</h4>
          <h6 className="subtitle">Manage your gallery photos in this space.</h6>
          <div className="desc">
            <p>During beta, upload as many photos as you want, but display <a style={{ color: "blue" }} onClick={() => { setIsmoreThan5PhotosMOdal(true) }}>only five.</a> A solution for more is being built now.</p>
          </div>
          {!UserPaymentStatus && <button className="btn" onClick={QueueModalHandler}>
            Show Up To 75 Photos
          </button>}


        </div>
        {/* <SortableList items={items} onSortEnd={onSortEnd} axis={"xy"} useDragHandle={true} /> */}
        {/* <div className="manage_photos_button text-center">
          {!Spinner ? <button onClick={ManageGalleryPhotosHandler} className="btn">
            Save
          </button>
            :
            <button className="btn">
              <span className="loader"></span>
            </button>
          }
        </div> */}

        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={boxesPerRow} // Adjust based on your design
            rowHeight={!IsMobile ? 250 : 120} // Adjust based on your design
            style={{ height: (items.length <= 6 ? 160 : boxesPerRow == 5 ? 110 : 140) * Math.ceil(items.length / 2) }} // Adjust based on your design
            className='gallery_manage_photos '
            disableDrag={!isHovered}
          >

            {items?.map((data, index) => (
              <GridItem key={data.id} className="queue_item " onClick={() => { imgSelectHandler(data.id) }}>

                <div className={`${index < (UserPaymentStatus ? 75 : 5) ? `manage_photo_item active ${SelectedPhotoIds.includes(data.id) ? 'selected' : ''}` : `manage_photo_item ${SelectedPhotoIds.includes(data.id) ? 'selected' : ''}`}`}
                  style={{ cursor: 'default' }}>
                  <div className="grab"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={grabClickHandler} // Prevent event bubbling
                  >
                    <i className="bi bi-arrows-move" style={{ fontSize: '20px' }} />

                  </div>
                  <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_300,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`} alt='img' />
                </div>
              </GridItem>))}
          </GridDropZone>
        </GridContextProvider>
      </div>}

      {ImagesLoader && <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <div className="spinner-border text-secondary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>}

      {IsFooter && ManageGalleryfirstPhoto.length > 0 && <div ref={Galleryfooterr}></div>}







      {IsmoreThan5PhotosMOdal && <>
        <div className="backdrop" />

        <div className="modal fade bph-modal show" id="moreThan5Photos" tabIndex={-1} aria-labelledby="sharePhotoModal" aria-hidden="true" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modalAnimation">
            <div className="modal-content">
              <div className="modal-body">
                {/* First */}
                <div className="onboarding_modal photoUpload_modal photoQueued">
                  <div className="modal_inner">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setIsmoreThan5PhotosMOdal(false) }} />
                    {/* logo */}
                    <div className="modal_logo d-none d-md-inline-flex">
                      <img src="./assets/img/logo/modal-logo.png" alt="img" />
                    </div>
                    {/* content */}
                    <div className="modal_content photoQueue_content max-five">
                      <div className="modal_title">
                        <h3 className="title"><img src="./assets/img/icons/info.svg" alt="img" />Five Max</h3>
                        <p className="subtitle">This is a new feature so for now, we're limiting
                          galleries to a max of five photos. </p>
                        <p className="desc">We have a solution in the works.</p>
                        <p className="desc">If its important to you to add more, Please let us know.</p>
                        <button className="btn" type="submit" onClick={() => { setIsmoreThan5PhotosMOdal(false) }}>OK</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
};

export default ManageGallery;
