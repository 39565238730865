import React from 'react'
import { ModalAction } from '../../Store/Modal-Slice'
import { useDispatch } from "react-redux";
import CreatedAtDateFormat from './CreatedAtDateFormat';

const CongratulationsNotification = ({ NotificationData }) => {

    let dispatch = useDispatch()
    let SharePhotobtnHandler = () => {
        dispatch(ModalAction.setSharePhotoModalState(true))

        dispatch(ModalAction.setShareModalPhotoInfo(
            {
                "path": NotificationData.photo_url,
                "id": NotificationData.photo_id,
            }
        ))
    }
    return (
        <>
            {/* Congratulations! card */}
            <div className="profile-card style--two">
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb rounded-0">
                            <img src="./assets/img/icons/congress.png" alt="profile" />
                        </div>
                        <div className="profile-card-content">
                            <ul className="profile-info-list">
                                <li><span className="title mb-1">Congratulations!</span></li>
                                <li><span className="title mb-1">Your photo is in the top ten this week!</span></li>
                                <li className="Congratulations-Title "><span className="title fw-normal">The community upvoted your photo to the top.</span> <CreatedAtDateFormat date={NotificationData.notification_createdAt} /></li>
                            </ul>
                            <div className='Congratulations-Share-btn_Container' >
                                <button onClick={SharePhotobtnHandler} className="btn btn--base btn--116 Congratulations-Share-btn" data-normal="Share" data-active="Shared" >Share</button>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <p className="Congratulations-Title-ForMobile mb-0 ">The community upvoted your photo to the top...MM</p> */}
            </div>
            {/* Congratulations! card */}

        </>
    )
}

export default CongratulationsNotification