import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from '../../utils/Address';
import ProfileInfoSkeleton from '../Skeleton/ProfileInfoSkeleton';
import { FollowAction } from '../../Store/FollowSlice';
import amplitude from 'amplitude-js';
import { GalleryAction } from '../../Store/Gallery-Slice';
import { fetchUserInfo } from '../../utils/API/AuthApi';
import { smoothScrollToElement } from '../../utils/Scroll';

const ProfileInfo = ({ onMyOtherWork,UserPaymentStatus }) => {
  let [defaultUserInfo, setdefaultUserInfo] = useState('')
  let [GenresValue, setGenresValue] = useState('')
  let [GoodAtValue, setGoodAtValue] = useState('')
  let [MeetForValue, setMeetForValue] = useState('')
  let [Followers, setFollowers] = useState('')
  let [Following, setFollowing] = useState('')
  let [GalleryPhotos, setGalleryPhotos] = useState([])
  let [GalleryPhotosPlace, setGalleryPhotosPlace] = useState([1, 2, 3])
 



  let MEApiState = useSelector(state => state.Modal.MeApiState)
  let imageObject = useSelector(state => state.Modal.CoverCropImg)

  let SetFollowStateHandler = (val) => {
    dispatch(FollowAction.setFollowState(val))
  }

  let address
  if (defaultUserInfo.state) {
    address = ShortAddress(defaultUserInfo.state, 21);
  }

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let Email = decryptedUserData.user_email
  // <-------------------------------------------->

  let GalleryUploadComponentState = useSelector(state => state.Gallery.GalleryUploadComponentState)
  // / <-------------------------------------------->

  let UploadedGalleryPhotos = useSelector(state => state.Gallery.SuccessfullyUploadedGalleryPhotos)

  // useEffect(() => {
  //   console.log(UploadedGalleryPhotos)
  //   console.log(GalleryPhotos)
  //   // if (UploadedGalleryPhotos.length > 0 ) {
  //     console.log("hello")

  //     setGalleryPhotos([...UploadedGalleryPhotos, ...GalleryPhotos])
  //     // dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos([]))
  //   // }
  // }, [UploadedGalleryPhotos])


  // / <-------------------------------------------->

  let AddGalleryPhotosInProfileInfoHandler = (e) => {
    smoothScrollToElement(e, '#MygalleryComponent')
    onMyOtherWork();
    dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: true }));
    dispatch(GalleryAction.setProfileInfoPlusBtnIsClick(true));
    dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: false }));
    // dispatch(GalleryAction.setIsManageGallery(false))
    amplitude.getInstance().logEvent("Photo Gallery Add Request");
  }


  let OpenGalleryComponent = (e) => {
    onMyOtherWork();
    smoothScrollToElement(e, '#MygalleryComponent')
  }
  // <-------------------------------------------->
  let dispatch = useDispatch()


  // <-------------------------------------------->
  function removeProtocolAndLimitLength(url, maxLength = 20) {
    if (!url) {
      return "";
    }

    const protocolRegex = /^(https?:\/\/)/i;
    const urlWithoutProtocol = url.replace(protocolRegex, '');

    if (urlWithoutProtocol.length <= maxLength) {
      return urlWithoutProtocol;
    }

    return urlWithoutProtocol.substring(0, maxLength - 2) + '..';
  }

  const modifiedURL = removeProtocolAndLimitLength(defaultUserInfo.website);


  //changing date format
  const dateString = defaultUserInfo.createdAt;
  const date = new Date(dateString);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  //


  const genresString = GenresValue ? Object.values(GenresValue).join(", ") : "";
  const GoodAtString = GoodAtValue ? Object.values(GoodAtValue).join(", ") : "";
  const MeetForString = MeetForValue ? Object.values(MeetForValue).join(", ") : "";


  useEffect(() => {
    dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
    let me = async function () {



      try {
        const response = await fetchUserInfo(Email);

  

        setdefaultUserInfo(response.data.data[0])
        setGenresValue(response.data.data[0].genres_list)
        setGoodAtValue(response.data.data[0].strengths)
        setMeetForValue(response.data.data[0].meet_for)
        setFollowers(response.data.data[0].Followers)
        setFollowing(response.data.data[0].Following)
        setGalleryPhotos(response.data.data[0].gallery_photos || [])
        dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos(response.data.data[0].gallery_photos || []));
      } catch (error) {



      }
    }
    me();
  }, [MEApiState])



  return (
    <>
      <main className="site-content" id="main">
        {!defaultUserInfo ? (
          <ProfileInfoSkeleton />
        ) : (
          <section className="profile-area">
            <div className="profile_cover" style={{ backgroundImage: `url(${imageObject.cropImgPath ? imageObject.cropImgPath : defaultUserInfo.compressedCoverPhoto})` }} />
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="about_profile">
                    <div className="about_profile_inner">
                      <div className="profile_user">

                        <img src={defaultUserInfo.photo_url ? defaultUserInfo.photo_url : `https://ui-avatars.com/api/?name=${defaultUserInfo.name}&size=197&rounded=true&background=01655a&color=fff`} alt="img" style={{ width: "100%", height: '100%' }} />

                        {UserPaymentStatus && <div className="profile_badge">
                          <img src="./assets/img/profile/profile-badge.png" alt="badge" />
                        </div>}


                        <div className="change_img" onClick={() => {
                          dispatch(ModalAction.setProfileUploadModalState(true))
                        }}><img src="./assets/img/icons/Camera.svg" alt="img" /></div>
                      </div>
                      <div className="profile_details_warp">
                        <div className="details_profile">
                          <div className="information">
                            <h4 className="name">{defaultUserInfo.name}</h4>
                            <span className="address">{address}</span>
                            {defaultUserInfo.facebook_link ||
                              defaultUserInfo.twitter_link ||
                              defaultUserInfo.instagram_link ||
                              defaultUserInfo.website ?
                              (<div className="contact_info">
                                <ul className="socials">
                                  {defaultUserInfo.facebook_link && <li>
                                    <Link to={`https://www.facebook.com/${defaultUserInfo.facebook_link}`} target="_blank"><img src="./assets/img/icons/facebook.svg" alt="img" /></Link>
                                  </li>}
                                  {defaultUserInfo.instagram_link && <li>
                                    <Link to={`https://www.instagram.com/${defaultUserInfo.instagram_link}`} target="_blank"><img src="./assets/img/icons/instagram.svg" alt="img" /></Link>
                                  </li>}
                                  {defaultUserInfo.twitter_link && <li>
                                    <Link to={`https://twitter.com/${defaultUserInfo.twitter_link}`} target="_blank"><img src="./assets/img/icons/twitter.svg" alt="img" /></Link>
                                  </li>}
                                </ul>
                                {defaultUserInfo.website && <div className="website">
                                  <Link to={`http://${defaultUserInfo.website}`} target="_blank">{defaultUserInfo.website}</Link>
                                </div>}
                              </div>) :
                              (<Link
                                to={"/edit-profile"}
                              >
                                {" "}
                                <p style={{ color: "#0000FF" }} onClick={() => window.scrollTo(700, 700)}>
                                  Add Your Socials So People Can Find You
                                </p>
                              </Link>)}
                          </div>
                          <div className="following_follower">
                            <h6 className="title" style={{ cursor: 'pointer' }}>
                              <Link to={"/people"} className="fllowing" onClick={() => { SetFollowStateHandler("following"); }}>{Following.count} Following</Link>
                              <Link to={"/people"} className="followers" onClick={() => { SetFollowStateHandler("follower") }}>{Followers.count} Followers</Link>
                            </h6>
                            <ul className="users">
                              {Following.data && Following.data.map((url, index) => (<li key={index} >
                                <Link to={"/people"} onClick={() => {
                                  SetFollowStateHandler("following");
                                }}><img src={url.photo_url ? url.photo_url : `https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="img" style={{ borderRadius: "50%" }} /></Link>
                              </li>))}

                              {Followers.data && Followers.data.map((url, index) => (<li key={index} >
                                <Link to={"/people"} onClick={() => {
                                  SetFollowStateHandler("follower");
                                }}><img src={url.photo_url ? url.photo_url : `https://ui-avatars.com/api/?name=${url.name}&size=40&rounded=true&background=01655a&color=fff`} alt="img" style={{ borderRadius: "50%" }} /></Link>
                              </li>))}
                            </ul>
                          </div>
                        </div>
                        <div className="profile_buttons">
                          <Link className="btn btn-edit" to={"/edit-profile"}>Edit Profile</Link>
                          <a onClick={() => {
                            dispatch(ModalAction.setCoverPhotoUploadModalState(true))
                          }} className="btn btn-edit"><img src="./assets/img/icons/Camera.svg" alt="img" />
                            Edit Cover</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="about_profile_desc">
                    <div className="profile_desc_text">
                      {
                        defaultUserInfo.about_me && defaultUserInfo.about_me !== "undefined" && <p>{defaultUserInfo.about_me}</p>
                      }
                    </div>
                    <div className="profile_system_desc">
                      <div className="profile_system_desc_inner">
                        {defaultUserInfo.camera_brand &&
                          defaultUserInfo.camera_brand !== "null" &&
                          (<div className="system_item">
                            <span>Camera System:</span>
                            <p>{defaultUserInfo.camera_brand.charAt(0).toUpperCase() + defaultUserInfo.camera_brand.slice(1)}</p>
                          </div>)}
                        <div className="system_item">
                          <span>Member since:</span>
                          <p>{formattedDate}</p>
                        </div>

                        <div className="system_item">
                          <span>I'm Good At:</span>
                          {GoodAtString ? <p>{GoodAtString}</p> : <Link to={"/edit-profile"} style={{ color: "blue" }} >Answer this question</Link>}
                        </div>
                        {genresString && <div className="system_item">
                          <span>Genres:</span>
                          <p>{genresString}</p>
                        </div>}
                        {
                          defaultUserInfo.photo_assistance &&
                          defaultUserInfo.photo_assistance !== "null" && (<div className="system_item">
                            <span>Why I'm Here:</span>
                            <p>{defaultUserInfo.photo_assistance}</p>
                          </div>)}

                        <div className="system_item">
                          <span>Let’s Meet For:</span>
                          {MeetForString ? <p>{MeetForString}</p> : <Link to={"/edit-profile"} style={{ color: "blue" }}>Answer this question</Link>}
                        </div>
                      </div>
                      <div className="others_work_content">
                        {/*  */}
                        {/*  */}
                        {/* 
                        {GalleryPhotos?.length > 0 ?
                          <>
                            <ul className="o_work_images">
                              
                            </ul>
                          </>
                          : */}
                        {<>
                          <p className="title"><span>Add photos to your Personal Gallery for members to see:</span></p>
                          <ul className="o_work_images">
                            <button style={{
                              border: 'none',
                              backgroundColor: "transparent",
                              filter: GalleryUploadComponentState.BtnDisable ? "opacity(0.5)" : "none",

                            }} disabled={GalleryUploadComponentState.BtnDisable}>
                              <li className="work_img uploading" onClick={AddGalleryPhotosInProfileInfoHandler} style={{ cursor: GalleryUploadComponentState.BtnDisable ? "default" : "pointer" }}>
                                <span>+</span>
                              </li>
                            </button>


                            {(UploadedGalleryPhotos.length > 0 ? UploadedGalleryPhotos.length < 3 ? [...UploadedGalleryPhotos, ...GalleryPhotosPlace] : UploadedGalleryPhotos : GalleryPhotosPlace)?.slice(0, 3).map((data, index) => (

                              <li onClick={OpenGalleryComponent} className={`${data.compressedImageId || data.url ? "work_img uploaded" : "work_img place"}`} key={index}>
                                <img
                                  src={
                                    data.compressedImageId
                                      ? `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_400,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`
                                      : data.url
                                        ? data.url
                                        : './assets/img/others-work/o-work-place.jpg'
                                  }
                                  alt="Image"
                                />
                              </li>
                            ))}
                          </ul>
                        </>}

                        {/* } */}


                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>




        )}
      </main >
    </>
  );
}

export default ProfileInfo