
import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { formatDate } from "../../utils/Formatteddate"
import { formatLikes } from "../../utils/FomattedLike"
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from '../../utils/Address';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton';
import useImageLoader from '../../Hooks/useImageLoader';
import ImageDescription from '../MainImageComponent/ImageDescription';
import { AES } from 'crypto-js';
import amplitude from 'amplitude-js';
import { useSelector } from 'react-redux';
import { followUser } from '../../utils/API/FollowApi';
import { performVote } from '../../utils/API/UpVoteApi';
import { LightBoxAction } from '../../Store/LightboxSlice';
import { SocketAction } from '../../Store/SocketSlice';
import sendCongratulations from '../../utils/API/CongratApi'


const ImageContainer = (props) => {

    let [upDownvote, setupDownvote] = useState(props.voted)
    let [votebtnactive, setvotebtnactive] = useState(props.voted || false)
    let [TotalVote, setTotalVote] = useState(props.TotalVotes)
    let [follow, setfollow] = useState(props.is_follow)
    let [IsCongratulate, setIsCongratulate] = useState(props.is_congrat)

    let dispatch = useDispatch()


    // ImageLoaded hook 
    let isImageLoaded = useImageLoader(props.img);

    let address = ShortAddress(props.Userlocation, 16);

    // formatDate function 
    const formattedDate = formatDate(props.postedDate);

    //// Like////

    const formattedLikes = formatLikes(TotalVote);

    // < --------Socket ------->
    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.Userid))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->

    let LightboxHandler = (event) => {
        event.preventDefault();
        dispatch(LightBoxAction.setLightBoxActiveImgId(props.postId))
        dispatch(ModalAction.setisLightboxModalOpen(true))
        amplitude.getInstance().logEvent('Expand Photo')
    }

    let navigate = useNavigate();

    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();

        if (props.loginUserid == props.Userid) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: props.postId,
                UserID: props.Userid,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        }

        window.scrollTo(0, 0)
    }

    let MainImgHandler = () => {
        dispatch(ModalAction.setPopupModalMessage({
            "joinModalHeading": "",
            "popupHeading": "You’re In.",
            "popupMessage": "Next, upvote the photo you were looking at. Give it a chance to be featured in our Tuesday email!",
            photoID: props.postId,
            userID: props.Userid,
        }))
        dispatch(ModalAction.setJoinNowModalState(true))
    }

    ////

    useEffect(() => {
        setupDownvote(props.voted || false);
        setvotebtnactive(props.voted || false);




    }, [props.voted]);


    let voteHandler = async () => {


        setvotebtnactive(!votebtnactive)
        setupDownvote(!upDownvote)

        let up_or_down_vote;
        if (upDownvote) {
            up_or_down_vote = "remove"
        } else {
            up_or_down_vote = "add"
            amplitude.getInstance().setUserId(props.loginUserid);
            amplitude.getInstance().logEvent('Upvote');
            SendNotificationFn()
        }
        try {

            const response = await performVote(props.postId, up_or_down_vote, props.usertoken);


            setTotalVote(response.data.data.voteCount)

        } catch (error) {

        }

    }


    // <------------------------->
    let Upvotebtnstate = useSelector(state => state.Modal.PopupModalMessage)

    useEffect(() => {

        if (props.postId && Upvotebtnstate.photoID == props.postId && !props.voted) {
            voteHandler()
            dispatch(ModalAction.setPopupModalMessage({

                photoID: '',
                userID: '',
            }))
        }
    }, [])
    // <-------------------------->



    let FollowHandler = async (event) => {
        event.preventDefault();
        setfollow(!follow)

        if (!follow) {
            SendNotificationFn()
        }
        try {
            const response = await followUser(props.Userid, !follow, props.usertoken, props.loginUserid, SendNotificationFn);
        } catch (error) {
        }
    }


    let CongratulationssHandler = async (event) => {
        event.preventDefault();
        setIsCongratulate(!IsCongratulate);

        try {
            const response = await sendCongratulations(props.usertoken, props.Userid, props.postId, IsCongratulate, props.loginUserid);
            SendNotificationFn()
        } catch (error) {

        }
    }
    //  &&

    let SharePhotobtnHandler = () => {
        dispatch(ModalAction.setSharePhotoModalState(true))

        dispatch(ModalAction.setShareModalPhotoInfo(
            props.loginUserid !== props.Userid ?
                {
                    "path": props.img,
                    "id": props.postId,
                    "shareType": "Share Other User Photo"
                }
                :
                {
                    "path": props.img,
                    "id": props.postId,
                    "shareType": ""
                }
        ))
    }


    return (
        <>
            <div className="feed_item order-2 order-lg-1">
                <div className={`feed_item_inner ${props.isLoggedIn && isImageLoaded ? 'logged-in' : ''}`}>
                    <Link onClick={props.isLoggedIn ? LightboxHandler : MainImgHandler} >
                        {!isImageLoaded ? (
                            <MainImageSkeleton />
                        ) : (
                            <img
                                className='main-img img-fluid'
                                src={props.img}
                                alt="img"
                            />)}
                    </Link>
                    <div className="feed_hover_text">
                        <div className="feed_user">
                            <Link
                                className='user_img'
                                onClick={props.isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler}
                                style={{
                                    backgroundImage: `url(${props.UserPhoto || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",

                                }}>
                                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} alt="img" />}
                            </Link>
                            <div className="user_text">
                                <Link to="#" className="user_name" onClick={props.isLoggedIn ? OtheruserProfileNavigateHandler : MainImgHandler} >
                                    {props.Name}
                                </Link>
                                <p className="user_meta">
                                    {address && <span className="location">{address}</span>}
                                    <span className="date">{formattedDate}</span>
                                </p>
                            </div>
                        </div>

                        <div className="feed_vote box-footer">
                            {props.loginUserid !== props.Userid && <div className="feed_follow">
                                {!follow ? <a onClick={props.isLoggedIn ? FollowHandler : MainImgHandler} className="btn btn-follow follow" >
                                    <img src="./assets/img/icons/follow.svg" alt="img" />
                                    <span>Follow</span>
                                </a> :
                                    <a onClick={FollowHandler} className="btn btn-follow ">
                                        <img style={{ height: "28px" }} src="./assets/img/icons/user-minus.svg" alt="img" />
                                        <span>Following</span>
                                    </a>}
                            </div>}
                            {props.congBtn && props.loginUserid !== props.Userid && (!IsCongratulate ? <a onClick={props.isLoggedIn ? CongratulationssHandler : MainImgHandler} className="btn cong-btn cong-not-send" style={{ gap: "5px" }}>
                                <img src="./assets/img/icons/star.svg" className="img-fluid cong-img" alt="img" />
                                <span>Congratulations!</span>
                            </a> :
                                <a style={{ cursor: "default", gap: "5px", padding: "10px 27px" }} className="btn cong-btn cong-send">
                                    <img src="./assets/img/icons/green2-star.svg" className="img-fluid cong-img" alt="img" />
                                    <span>Congratulated</span>
                                </a>)}
                            <a
                                id='PhotoLikeBtn.'
                                onClick={props.isLoggedIn ? voteHandler : () => {
                                    dispatch(ModalAction.setPopupModalMessage({
                                        "joinModalHeading": "to upvote",
                                        "popupHeading": "Your Upvote is Official.",
                                        "popupMessage": "You’re in! Don’t forget to check your email Tuesday to see if this photo is one of the week’s most upvoted.",
                                        photoID: props.postId,
                                        userID: props.Userid,
                                    }))

                                    dispatch(ModalAction.setJoinNowModalState(true))
                                }} className={`btn btn-vote ${props.congBtn ? "weeklyTop10Linke-btn" : ''}  ${votebtnactive ? "active" : ""}`}>
                                <img src="./assets/img/icons/arrow-up.svg" alt="img" />
                                <span>{formattedLikes}</span>

                            </a>
                        </div>
                    </div>
                    {props.isLoggedIn && <ul className="feed-list">
                        <li>

                            <Link onClick={SharePhotobtnHandler}>Share <img src="./assets/img/icons/share-icon.svg" alt="img" className="img-fluid share-img" /></Link>
                        </li>
                        {/* <li>
                            <a onClick={LightboxHandler}>
                                <img src="./assets/img/icons/expend-icon.svg" alt="img" className="img-fluid" /></a>
                        </li> */}
                    </ul>}
                </div>
                {<ImageDescription
                    displayUserSocailPhoto={props.displaySocailUserPhoto}
                    usertoken={props.usertoken}
                    Name={props.display_name}
                    loginUserid={props.loginUserid}
                    isLoggedIn={props.isLoggedIn}


                    img={props.img}
                    postId={props.postId}
                    First1Comments={props.First1Comments}
                    TotalComments={props.TotalComments}
                    show={props.show}
                    sharebtn={props.sharebtn}
                    description={props.description}
                    feedback={props.feedback}
                    metadata={props.metadata}
                    Userid={props.Userid}
                    UserPaymentStatus={props.UserPaymentStatus}
                />}
            </div>


        </>
    )
}

export default ImageContainer