import React, { useState, useEffect, useRef } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import GalleryNOPhoto from './GalleryNOPhoto'
import { formatDate } from '../../utils/Formatteddate';
import { useDispatch } from 'react-redux';
import { LightBoxAction } from '../../Store/LightboxSlice';
import amplitude from 'amplitude-js';
import { ModalAction } from '../../Store/Modal-Slice';
import { GalleryAction } from '../../Store/Gallery-Slice';
import { useSelector } from 'react-redux';
import { loadGalleryPhotos } from '../../utils/API/GalleryApi';


let increment = 0;

const MainGallery = ({ usertoken, OtherUserGAllery, OtherUserID, ReloadGalleryPhotolist, UserName, CheckPhotoListIsEmptyFn, UserPaymentStatus, OtherUserPaymentStatus }) => {
    let [GalleryfirstPhoto, setGalleryfirstPhoto] = useState([])
    let [MoreGalleryPhoto, setMoreGalleryPhoto] = useState([])
    let [spinner, setspinner] = useState(false)
    let [Loader, setLoader] = useState(false)
    let [EmptyGallery, setEmptyGallery] = useState(false)
    let [IsFooter, setIsFooter] = useState(true)
    


    let dispatch = useDispatch()
    let Galleryfooterr = useRef();

    let AfterUploadPhoto = useSelector(state => state.Modal.StateAfterUploadPhoto)
    let GalleryUploadComponentState = useSelector(state => state.Gallery.GalleryUploadComponentState)
    let PlusBtnIsClick = useSelector(state => state.Gallery.ProfileInfoPlusBtnIsClick)
    let isViewGallery = useSelector(state => state.Gallery.ViewGallery)




    let PhotoUploadModalHandler = () => {


        dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: true }));
        dispatch(GalleryAction.setProfileInfoPlusBtnIsClick(true));
        dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: false }));


    }
    // <-------------------------------------------->


    let LightboxHandler = (photoId) => {

        dispatch(LightBoxAction.setLightBoxActiveImgId(photoId))
        amplitude.getInstance().logEvent('Expand Photo')
        dispatch(ModalAction.setisLightboxModalOpen(true))
    }
    // <-------------------------------------------->

    let apiCall = (e) => {
        if (e[0].isIntersecting) {
            increment = increment + 5;

            let LoadPhotos = async function () {

                setspinner(true)
                try {

                    const response = await loadGalleryPhotos(usertoken, OtherUserID, increment, { sortingType: 'upload_date', value: "new" }, (!OtherUserGAllery ?  UserPaymentStatus : OtherUserPaymentStatus));

                    const newPhotos = response.data.data;
                    if (response.data.data.length < 10) {
                        setIsFooter(false)
                    }
                    setMoreGalleryPhoto(prevPhotos => [...prevPhotos, ...newPhotos])


                    setspinner(false)


                } catch (error) {
                    console.log(error)
                }
            }
            LoadPhotos();
        }
    }

    useEffect(() => {

        const Observer = new IntersectionObserver(apiCall, {
            root: null,
            threshold: 0

        })

        if (Galleryfooterr.current) Observer.observe(Galleryfooterr.current)
        return () => {
            if (Galleryfooterr.current) Observer.unobserve(Galleryfooterr.current);
        };
    }, [GalleryfirstPhoto, GalleryUploadComponentState])


    useEffect(() => {
        // if (PlusBtnIsClick) {
        //     dispatch(GalleryAction.setProfileInfoPlusBtnIsClick(false))
        //     return
        // }
        // if (isViewGallery.manageGallery) {
        //     return
        // }
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
        // dispatch(GalleryAction.setIsManageGallery(false))
        dispatch(GalleryAction.setViewGallery({ mainGallery: true, manageGallery: false }))
        AllloadPhoto = []
        setMoreGalleryPhoto([])
        setGalleryfirstPhoto([])
        setspinner(true)
        setEmptyGallery(false)

        increment = 0


        let LoadGalleryPhotos = async function () {

            try {

                const response = await loadGalleryPhotos(usertoken, OtherUserID, increment, { sortingType: 'upload_date', value: "new" }, (!OtherUserGAllery ?  UserPaymentStatus : OtherUserPaymentStatus));
                let data = response.data.data
                console.log(data)
                setGalleryfirstPhoto(data)

                // dispatch(GalleryAction.setSuccessfullyUploadedGalleryPhotos(data));
                setspinner(false)
                if (data.length < 10) {
                    setIsFooter(false)
                }
                if (data.length == 0) {
                    setEmptyGallery(true)
                    CheckPhotoListIsEmptyFn(true)
                } else {
                    CheckPhotoListIsEmptyFn(false)
                    setEmptyGallery(false)
                    dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
                }

            } catch (error) {
                console.log(error)
            }

        }
        LoadGalleryPhotos();
    }, [])



    let AllloadPhoto;
    if (increment === 0) {
        AllloadPhoto = [...GalleryfirstPhoto]
    } else {

        AllloadPhoto = [...GalleryfirstPhoto, ...MoreGalleryPhoto]

    }

    useEffect(() => {

        if (AllloadPhoto) {

            dispatch(LightBoxAction.setLightBoxState({ PhotoArr: AllloadPhoto, LoginUserPhotos: !OtherUserGAllery, gallery: true }))

        }
    }, [AllloadPhoto])


    return (
        <>
            <div className="gutter-sizer">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}

                >
                    <Masonry gutter="20px">
                        {AllloadPhoto?.map((data, index) => (<div className="grid-image-item" key={index} onClick={() => { LightboxHandler(data.id) }} style={{ cursor: 'pointer' }}>
                            {/* <div className="gallery_expanded" >
                                <img className="expanded" src="./assets/img/icons/expend-icon.svg" alt='img' />
                            </div> */}
                            <img className="gallery_img " src={data.url ? data.url : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_500,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`} alt='img' />
                            <div className="content">
                                <h6 className="title">{!data.isWeeklyTopTen && !data.isHunted ? "" : (data.isWeeklyTopTen ? "Weekly Top Ten" : "Hunted Photo")}</h6>
                                <span className="date">{formatDate(data.createdAt)}</span>
                            </div>
                        </div>))}

                    </Masonry>

                </ResponsiveMasonry>

                {EmptyGallery && !isViewGallery.manageGallery && (!OtherUserGAllery ? <GalleryNOPhoto handleUploadClick={PhotoUploadModalHandler} /> : <h4>{`${UserName} hasn’t added photos to their personal gallery yet.`}</h4>)}


            </div>

            {spinner && <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <div className="spinner-border text-secondary" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>}

            {IsFooter && AllloadPhoto.length > 0 && <div ref={Galleryfooterr}></div>}
        </>


    )
}

export default MainGallery
