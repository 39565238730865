// apiUtils.js

import axios from 'axios';

export const loadGalleryPhotos = async (usertoken, OtherUserID, increment, FilterValue, UserPaymentStatus) => {

    try {
        let payload;
        if (OtherUserID) {
            payload = {
                "user_id": OtherUserID,
                "offset": increment,
                "sortingType": "upload_date",
                "value": "new",
                "isPaid": UserPaymentStatus
            };
        } else {
            payload = {
                "offset": increment,
                "sortingType": FilterValue.sortingType,
                "value": FilterValue.value,
                "isPaid": UserPaymentStatus
            };
        }
        console.log(payload, "UserPaymentStatus")
        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/view-gallery`, payload, { headers });

        return response;
    } catch (error) {
        throw error;
    }
};




export const ManageGalleryPhotos = async (usertoken, PhotoIdArray) => {

    try {
        let payload = {
            "photoId": PhotoIdArray,
            "sortingType": "arranged_photo",
            "value": "new"
        };

        const headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/manage-gallery`, payload, { headers });

        return response;
    } catch (error) {
        throw error;
    }
};



export const DeleteGalleryPhoto = async (usertoken, photoIDArray) => {
    try {
        const payload = { "photoId": photoIDArray };
        const headers = {
            Authorization: `Bearer ${usertoken}`,
            'Content-Type': 'application/json',
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/delete-gallery`, payload, { headers });
        return response;
    } catch (error) {
        console.error("Error removing queued photo:", error);
        throw error;
    }
};






export const checkImageLimit = async (payload, usertoken,) => {

    let headers = {
        'Authorization': `Bearer ${usertoken}`
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/get_photo_per_week`, payload, { headers });
        return response
    } catch (error) {

        throw error;
    }
};
