import React from 'react'
// import Header from '../Components/Layout/Header'
import { Link } from 'react-router-dom'
import PhotoFeed from '../Components/Feed/PhotoFeed'
import { useEffect, useState } from 'react'
import { decryptUserData } from '../utils/encryptedData'
import ReactGA from "react-ga"
import { useDispatch } from 'react-redux'
import { ModalAction } from "../Store/Modal-Slice";
import LastWeekTop10 from '../Components/Top10/LastWeekTop10'
import { testimonialsAPI } from '../utils/API/TestimonialApi'
import SubNavbar from '../Components/Layout/SubNavbar'


const Home = () => {

  function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [testimonialUserData, settestimonialUserData] = useState([]);


  let dispatch = useDispatch()
  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let isLoggedIn = decryptedUserData.isLoggedIn

  // <-------------------------------------------->




  useEffect(() => {
    let testimonialsList = async () => {
      try {
        const response = await testimonialsAPI();
        settestimonialUserData(response.data.data)
      } catch (error) {

      }

    }
    testimonialsList()
  }, [])



  useEffect(() => {
    if (!isLoggedIn) {
      const timer = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
        document.querySelector('.carousel-control-next').click()

        const slider = document.querySelector('.nameSlider');
        const slides = document.querySelectorAll('.nameslide');
        let currentIndex = 0;

        function updateSlider() {
          currentIndex = (currentIndex + 1) % slides.length;
          const nextSlide = slides[currentIndex];
          const previousSlide = slides[(currentIndex + slides.length - 1) % slides.length];

          slider.appendChild(previousSlide);
          nextSlide.classList.add('active');
          previousSlide.classList.remove('active');
        }
        updateSlider()
      }, 30000);
      return () => clearInterval(timer);
    }
  }, [isLoggedIn]);



  const singleSliderData = [
    {
      title: 'Greg Moore, Kansas, USA',
      date: 'Top Ten Winner',
    },

    {
      title: 'Samuel Messerini, Livorno, Italy',
      date: 'Top Ten Winner',
    },


    {
      title: 'Rick Teal, Washington, USA',
      date: 'Top Ten Winner',
    },


    {
      title: 'Lola Delabays, Lyon, France',
      date: 'Top Ten Winner',
    },

  ];

  const backgroundImageURLs = [
    'https://bph051023a.s3.amazonaws.com/hero-image/hero-image1.jpeg',
    'https://bph051023a.s3.amazonaws.com/hero-image/hero-image-2.jpeg',
    'https://bph051023a.s3.amazonaws.com/hero-image/hero-image-3.jpeg',
    'https://bph051023a.s3.amazonaws.com/hero-image/hero-image-4.jpg',
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <>
      <main className={`${isLoggedIn ? "site-content" : ''}`} id="main">
        {/* <Header /> */}
        {/* <LoginHeader/> */}

        {/* <!-- start: Hero Section --> */}
        {!isLoggedIn &&


          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="300000000000000">
            <div className="carousel-inner">
              {backgroundImageURLs.map((url, index) => (<div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                <section
                  className="hero-section d-flex align-items-center"
                  id="hero"
                  style={{
                    background: `linear-gradient(
            0deg,
            rgba(5, 8, 30, 0.2),
            rgba(5, 8, 30, 0.2)
        ), url(${url})`,
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="hero_inner_content">
                          <h1 className="title">Growing Our Photography Together</h1>
                          <p className="text">
                            Join our friendly community of amateur and aspiring photographers to find inspiration, get discovered and meet others.
                          </p>
                          <Link to="" className="btn" onClick={() => { dispatch(ModalAction.setJoinNowModalState(true)) }}>
                            See for yourself
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>))}

            </div>

            <button style={{ display: 'none' }} className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>

        }

        {/* <!-- hero bottom slider --> */}
        {!isLoggedIn && <section className="bottom-slider-section">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="hero_bottom_slider">
                  <div className="bottom_slider_inner nameSlider" >

                    <div className="single_slider active nameslide">
                      <div className="title">{singleSliderData[0].title}</div>
                      <span className="date">{singleSliderData[0].date}</span>
                    </div>

                    <div className="single_slider nameslide">
                      <div className="title">{singleSliderData[1].title}</div>
                      <span className="date">{singleSliderData[1].date}</span>
                    </div>

                    <div className="single_slider  nameslide">
                      <div className="title">{singleSliderData[2].title}</div>
                      <span className="date">{singleSliderData[2].date}</span>
                    </div>

                    <div className="single_slider  nameslide">
                      <div className="title">{singleSliderData[3].title}</div>
                      <span className="date">{singleSliderData[3].date}</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}
        {/* <!-- end: Hero Section --> */}

        {/* start: Why Join Section */}
        {!isLoggedIn && <section className="why-join-section">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section_title text-center">
                  <h3 className="title">Why Join the Big Photo Hunt</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-3">
                <div className="why_join_single">
                  <div className="thumb">
                    <img src="./assets/img/why-join/photography-club.png" alt="img" />
                  </div>
                  <div className="content">
                    <h6 className="title">Be Part of a Global Amateur Photography Club</h6>
                    <p className="text">Gain an instant following and become better by learning from others.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="why_join_single">
                  <div className="thumb">
                    <img src="./assets/img/why-join/skills.png" alt="img" />
                  </div>
                  <div className="content">
                    <h6 className="title">Share & Connect with Like-Minded People</h6>
                    <p className="text">Find inspiration and get your photography seen while meeting new like-minded photographers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="why_join_single">
                  <div className="thumb">
                    <img src="./assets/img/why-join/inspiration.png" alt="img" />
                  </div>
                  <div className="content">
                    <h6 className="title">Get Motivating Feedback &amp; Improve Your Skills</h6>
                    <p className="text">Stay motivated, learn and improve your skills with thoughtful perspectives from around the
                      world.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="why_join_single">
                  <div className="thumb">
                    <img src="./assets/img/why-join/recognized.png" alt="img" />
                  </div>
                  <div className="content">
                    <h6 className="title">Be Recognized Weekly &amp; Monthly!</h6>
                    <p className="text">Earn a chance to have your photography recognized through weekly and monthly photo
                      competitions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="why_join_btn text-center">
                  <a
                    className="btn"

                    onClick={() => {
                      dispatch(ModalAction.setPopupModalMessage({ "joinModalHeading": "", }))
                      dispatch(ModalAction.setJoinNowModalState(true))
                    }}
                  >Join Now</a>
                </div>
              </div>
            </div>
          </div>
        </section>
          // : <LastWeekTop10 token={token} userid={userid} />
        }
        {/* start: Why Join */}




        {!isLoggedIn &&
          <section className="why-do-think">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section_title text-center" style={{ maxWidth: "none" }}>
                    <h3 className="title">Why do we think the Big Photo Hunt
                      <br />
                      is Awesome?</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {testimonialUserData.length > 0 && <div className="why_do_think_items  owl-carousel">
                    {testimonialUserData?.map((user, index) => (<a className="why_do_think_item">
                      <div className="user_details" key={user.id}>
                        <div className="user_img"><img src={user.profile_pic} alt="img" /></div>
                        <div className="user_content">
                          <h6 className="name">{user.name}</h6>
                          <span className="address">{user.state}</span>
                          <ul className="other_info d-none d-md-block">
                            <li><strong>Camera:</strong>{user.camera_brand}</li>
                            <li><strong>Genres:</strong>{user.genres_list}</li>
                          </ul>
                        </div>
                      </div>
                      <ul className="other_info d-md-none">
                        <li><strong>Camera:</strong> {user.camera_brand}</li>
                        <li><strong>Genres:</strong>{user.genres_list}</li>
                      </ul>
                      <div className="think_content">
                        <p>“{user.quote}”</p>
                      </div>
                    </a>
                    ))}

                  </div>}
                </div>
              </div>
            </div>
          </section>}




        {/* <!-- start: Photo Feed --> */}
        {isLoggedIn ? <SubNavbar photoTabstate={true} />
          :
          <PhotoFeed />}
        {/* <!-- end: Photo Feed --> */}
      </main>

      {AddLibrary("./assets/js/owl.carousel.min.js")}
      {AddLibrary("./assets/js/main.js")}

    </>
  )

}



export default Home