import React from 'react'
import UserData from './UserData'
import FollowAndThanksButton from './FollowAndThanksButton'


const WelcomeNotification = ({ NotificationData,usertoken,loginUserid }) => {
    return (
        <>

            <UserData NotificationData={NotificationData} message={"just welcomed you!"}>
                <FollowAndThanksButton
                    thanksBtnHidden={true}
                    usertoken={usertoken}
                    NotificationData={NotificationData}
                    loginUserid={loginUserid}
                     />
            </UserData>
           

        </>
    )
}

export default WelcomeNotification