import React from 'react'
import UserData from './UserData'
import FollowAndThanksButton from './FollowAndThanksButton'



const ThanksForCongratsNotification = ({ NotificationData, usertoken,loginUserid }) => {
    return (
        <>
            <UserData NotificationData={NotificationData} message={"thanked you for your Weekly Top Ten congratulations!"}>
                <FollowAndThanksButton
                    thanksBtnHidden={true}
                    usertoken={usertoken}
                    NotificationData={NotificationData}
                    loginUserid={loginUserid}
                />
            </UserData>
            {/* <div className="profile-card style--two">
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb rounded-0">
                            <img src="assets/images/thumb/ac6.png" alt="profile" />
                        </div>
                        <div className="profile-card-content">
                            <ul className="profile-info-list">
                                <li className="name_info"><span className="title"><a   className="text-title ">Bobert Bobertson</a> </span> <span className="value font--sm text-title"> thanked you for your Weekly Top Ten congratulations!</span></li>
                                <li className="mb-1 location_info"><span className="value font--sm ">Boston, USA</span></li>
                                <li className="d-none d-sm-block camera_info"><span className="title ">Camera: </span> <span className="value">Nicon</span></li>
                                <li className="d-none d-sm-block genres_info"><span className="title">Genres: </span> <span className="value">Street, Landscape, Art, Nature</span></li>
                            </ul>
                            <button className="btn btn--base btn--116" data-normal="Follow" data-active="Following"  >Follow</button>
                        </div>
                    </div>
                </div>
                <ul className="text-start profile-info-list d-sm-none">
                    <li className="camera_info"><span className="title text-title">Camera: </span> <span className="value">Nicon</span></li>
                    <li className="genres_info"><span className="title text-title">Genres: </span> <span className="value">Street, Landscape, Art, Nature</span></li>
                </ul>
            </div> */}

        </>
    )
}

export default ThanksForCongratsNotification