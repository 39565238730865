import React from 'react'
import { useState } from 'react'
import FollowNotification from './FollowNotification'
import CongratulationsNotification from './CongratulationsNotification'
import UpVoteNotification from './UpVoteNotification'
import ThanksForUpvoteNotification from './ThanksForUpvoteNotification'
import WelcomeNotification from './WelcomeNotification'
import SomeoneYouFollowPostedAPhoto from './SomeoneYouFollowPostedAPhoto'
import CommentNotification from './CommentNotification'
import ReplyToYOURComment from './ReplyToYOURComment'
import WhoYouFollowInTopTenNotification from './WhoYouFollowInTopTenNotification'
import ReceviceCongratulationNotification from './ReceviceCongratulationNotification'
import ThanksForCongratsNotification from './ThanksForCongratsNotification'
import LikedYourComment from './LikedYourComment'
import ThanksForCommentLikeNotification from './ThanksForCommentLikeNotification'


const ActivityPageNotificationList = (props) => {
    let [NotificationData, setNotificationData] = useState(props.NotificationsData)


    return (
        <>




            {
                NotificationData.notification_type == "follow_user" &&
                <FollowNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "weekly_top_ten" &&
                <CongratulationsNotification
                    NotificationData={NotificationData.message}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "upvote_photo" &&
                <UpVoteNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "thanks_on_upvote" &&
                <ThanksForUpvoteNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "welcome_user" &&
                <WelcomeNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "posted_photo" &&
                <SomeoneYouFollowPostedAPhoto
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "comment_on_photo" &&
                <CommentNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    CeatedAt={NotificationData.createdAt}
                    loginUserid={props.loginUserid}
                />
            }


            {
                NotificationData.notification_type == "reply_to_comment" &&
                <ReplyToYOURComment
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    CeatedAt={NotificationData.createdAt}
                    loginUserid={props.loginUserid}
                />
            }


            {
                NotificationData.notification_type == "follower_in_weekly_top_ten" &&
                <WhoYouFollowInTopTenNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid} />
            }

            {
                NotificationData.notification_type == "congratulate_weekly_toptenuser" &&
                <ReceviceCongratulationNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "thanks_on_weeklytopten_congratulation" &&
                <ThanksForCongratsNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "upvote_comment" &&
                <LikedYourComment
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }

            {
                NotificationData.notification_type == "thanks_on_upvote_comment" &&
                <ThanksForCommentLikeNotification
                    NotificationData={NotificationData.message}
                    usertoken={props.usertoken}
                    loginUserid={props.loginUserid}
                />
            }



            {/* since visit last time */}
            {NotificationData.notification_type == "unviewed" && < div className="since_visit_last_time">
                <span className="text"><img src="./assets/img/icons/arrow-up-activity.svg" alt='img' />Since your last visit<img src="./assets/img/icons/arrow-up-activity.svg" alt='img' /></span>
            </div >}
            {/* !since visit last time */}
        </>
    )
}

export default ActivityPageNotificationList