import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { loginSchemas } from '../../schemas';
import { SocialMediaAction } from "../../Store/SocialMedia-Slice";
import { ModalAction } from "../../Store/Modal-Slice";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../Store/Login-Slice";
import { useSelector } from "react-redux";
import { enc, AES } from 'crypto-js';
import SocialMediaButtons from "./SocialMediaButtons";
import { Loginapi } from "../../utils/API/AuthApi";

let initialValues = {
  email: '',
  password: ''

}



const SignInModal = (props) => {
  let [spinner, setspinner] = useState(false)
  let [error, seterror] = useState(false)
  let [forgotpasswordState, setforgotpasswordState] = useState(false)
  let [forgotpasswordStateAfterSubmit, setforgotpasswordStateAfterSubmit] = useState(false)
  let [ResetInpuValue, setResetInpuValue] = useState("")
  let [ResetpasswordError, setResetpasswordError] = useState("")

  let navigate = useNavigate();

  let dispatch = useDispatch()

  let popupData = useSelector(state => state.Modal.PopupModalMessage)




  let modalCloseHandler = () => {
    dispatch(ModalAction.setSignInModalState(false))
    dispatch(SocialMediaAction.getSocialMediaUserInfo([]))
    // setforgotpasswordState(false)
    // resetForm();
    // setResetpasswordError('')
    // seterror(false)
  }

  let ResetPasswordChangeHandler = (e) => {
    let value = e.target.value;
    setResetInpuValue(value)
    setResetpasswordError('')
  }




  //////////////////////////////////////////////////////////////////////////////


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchemas,
    validateOnBlur: false, // Disable onBlur validation

    onSubmit: async (value, action) => {
      let userEmail = value.email;
      let lowercaseEmail = userEmail.toLowerCase()
      let userPassword = value.password;
      setspinner(true)
      try {

        const response = await Loginapi(lowercaseEmail, userPassword)

        let user_type = response.data.data[0].user_type
        let token = response.data.data[1].token
        setspinner(false)
        if (token && user_type == "email") {
          
          const userData = {
            userid: response.data.data[0].id,
            useDisplay_name: response.data.data[0].name,
            user_email: response.data.data[0].email,
            user_type: response.data.data[0].user_type,
            token: token,
            Display_UserSocailPhoto: response.data.data[0].photo_url,
            CreatedAt: response.data.data[0].createdAt,
            isLoggedIn: true,
            state: response.data.data[0].state
          };
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
          localStorage.setItem('Data', encryptedUserData);

          dispatch(LoginAction.setIsLoggedIn(true))

          if (!localStorage.getItem("source") && !popupData.photoID) {
            navigate('/', { replace: true })

          } else if (userData.userid !== popupData.userID && !localStorage.getItem("source")) {
            let userData = {
              PhotoID: popupData.photoID,
              UserID: popupData.userID
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);

          } else if (userData.userid == popupData.userID && !localStorage.getItem("source")) {
            navigate('/profile', { replace: true })
            dispatch(ModalAction.setPopupModalMessage({
              "joinModalHeading": "",
            }))
          }

          window.scrollTo(0, 0);
          dispatch(ModalAction.setSignInModalState(false))
          dispatch(SocialMediaAction.getSocialMediaUserInfo([]))

        } else {
          document.querySelector('.google').click()
          dispatch(ModalAction.setSignInModalState(false))
        }
        seterror(false)
        action.resetForm();

      } catch (error) {
        let message = error.response.data.message;

        if (error == "Sorry. We don't recognize that username or password. If you forgot your password,") {
          seterror(message);
        } else {
          seterror(message);
        }

        setspinner(false)

      }

    }
  })


  let ResetPasswordHandler = async (event) => {
    event.preventDefault();

    setspinner(true)
    try {

      const payload = {
        "email": ResetInpuValue.trim().toLowerCase(),

      };



      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgetPassword`, payload, {});
      setforgotpasswordStateAfterSubmit(true)
      setspinner(false)

    } catch (error) {
      let message = error.response.data.message;


      setResetpasswordError(message)
      setspinner(false)
    }
  }


  return (
    <>
      <div className="backdrop" />
      <div
        className="modal fade bph-modal show "
        id="signInModal"
        tabIndex={-1}
        aria-labelledby="signInModal"
        aria-hidden="true"
        style={{ display: 'block' }}

      >
        <div className="modal-dialog modal-dialog-centered modalAnimation">
          <div className="modal-content">
            <div className="modal-body">
              <div className="onboarding_modal signIn_modal">
                <div className="modal_inner">
                  <button
                    type="button"
                    className="btn-close signInModalClose"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={modalCloseHandler}
                  ></button>
                  {/* <!-- logo --> */}
                  <div className="modal_logo d-none d-md-inline-flex">
                    <img src="./assets/img/logo/modal-logo.png" alt="img" />
                  </div>

                  {/* <!-- content --> */}
                  {!forgotpasswordState ? <div className="modal_content">
                    <div className="modal_title">
                      <h4 className="title">Sign In</h4>
                    </div>

                    {/* <!-- log reg button --> */}

                    <SocialMediaButtons />

                    {/* <!-- divider --> */}
                    <div className="divider">
                      <span>or via email</span>

                    </div>

                    {/* <!-- form --> */}
                    {error && <p className="eerror" style={{ textAlign: 'center' }}>

                      <>
                        {error}{" "}
                        {error !== "Your account is blocked" && <Link style={{ color: 'blue' }} onClick={() => { setforgotpasswordState(true) }}>click here.</Link>}
                      </>
                    </p>}



                    <div className="form">
                      <form onSubmit={handleSubmit} className="sighIn_form">
                        <div className="form_group">
                          <input
                            type="email"
                            placeholder="Email"
                            name='email'
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                          />
                          <div className="form_error"></div>
                        </div>
                        {errors.email && touched.email ? (<p className="eerror" >{errors.email} </p>) : null}
                        <div className="form_group">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   setFieldTouched('password', true);
                            // }}
                            value={values.password}
                            onBlur={handleBlur}
                          />
                          <div className="form_error"></div>
                        </div>
                        {errors.password && touched.password ? (<p className="eerror" >{errors.password} </p>) : null}

                        <div className="buttons">
                          {!spinner ?
                            <button className="btn" type="submit"> Sign In </button>
                            :
                            <button className="btn"  >
                              <span className="loader"></span>
                            </button>}
                        </div>
                      </form>
                    </div>

                    {/* <!-- privacy text --> */}
                    <div className="privacy_text">
                      <Link style={{ color: "gray", textDecoration: 'underline' }} onClick={() => { setforgotpasswordState(true) }}>Forgot Password</Link>
                      <p style={{ marginTop: "10px" }}>
                        We will never post to any of your accounts without your
                        permission
                      </p>
                    </div>

                  </div>
                    :
                    <div className="modal_content">
                      <form onSubmit={ResetPasswordHandler}>
                        <div className="modal_title" style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: '20px' }}>
                          <h3 className="title">Reset Your Password</h3>
                          <p style={{ color: "black", fontSize: "1rem", lineHeight: '20px' }} className="subtitle">{!forgotpasswordStateAfterSubmit ? "Please enter the email address that is associated with your account.We will send a link to recover your password." : "Great! If that email address exists in our database we just sent password reset instructions."} </p>
                          {!forgotpasswordStateAfterSubmit ?

                            <div className="form_group">
                              {ResetpasswordError && <p style={{ marginBottom: '10px', textAlign: "center" }} className="eerror" >{ResetpasswordError} </p>}
                              <input
                                type="email"
                                placeholder="Email"
                                name="password"
                                required
                                onChange={ResetPasswordChangeHandler}
                              />

                            </div>
                            :

                            <p style={{ color: "black", fontSize: "1rem", lineHeight: '20px' }} className="subtitle">
                              If you do not receive an email please double check your email address and try again, look in your spam folder,or email us at <Link style={{ color: "blue" }} to="mailto:hola@bigphotohunt.com">
                                hola@bigphotohunt.com
                              </Link> </p>}

                          {!forgotpasswordStateAfterSubmit && (!spinner ?
                            <button className="btn" type="submit"> Submit </button>
                            :
                            <button className="btn"  >
                              <span className="loader"></span>
                            </button>)}

                          <a onClick={() => {
                            setforgotpasswordState(false)
                            setforgotpasswordStateAfterSubmit(false)
                          }} style={{ color: 'blue', textDecoration: "underline" }}>Back</a>

                        </div>
                      </form>
                    </div>

                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  );
};

export default SignInModal;
