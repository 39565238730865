import React from 'react'
import UserData from './UserData';
import FollowAndThanksButton from './FollowAndThanksButton';
import { AES } from 'crypto-js'
import { useNavigate } from 'react-router-dom';
import { ShortAddress } from '../../utils/Address';
import CreatedAtDateFormat from './CreatedAtDateFormat';

const UpVoteNotification = ({ NotificationData, usertoken, loginUserid }) => {

    let navigate = useNavigate()

    let NavigatorHandler = () => {
        let userData = {
            PhotoID: "",
            UserID: NotificationData.sender_id,
        }
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
        navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        window.scrollTo(0, 0)
    }

    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();

        if (NotificationData.receiver_id === NotificationData.photo_owner_id) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: NotificationData.photo_id,
                UserID: NotificationData.photo_owner_id,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        }

        window.scrollTo(0, 0)
    }


    ///address
    let address = ShortAddress(NotificationData.state, 16);

    const genresString = NotificationData.genres_list ? Object.values(NotificationData.genres_list).slice(0, 3).join(", ") : "";

    return (
        <>
            {/* <UserData NotificationData={NotificationData} message={"upvoted your photo"}>

               

                <div className="profile-card-content d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-xxl-between flex-grow-1">

                    <div className='d-none d-sm-flex '>
                        <FollowAndThanksButton
                            usertoken={usertoken}
                            ThanksFor={'thanks_on_upvote'}
                            NotificationData={NotificationData}
                            loginUserid={loginUserid}
                        />
                    </div>

                    <a className="comment-photo d-none d-sm-inline-flex" href>
                        <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="d-block" />
                    </a>
                </div>
            </UserData> */}

            <div className="profile-card style--two">
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb align-self-start align-self-xxl-center">
                            <a onClick={NavigatorHandler}>
                                <img src={NotificationData.profile_pic ? NotificationData.profile_pic : `https://ui-avatars.com/api/?name=${NotificationData.name}&size=40&rounded=true&background=01655a&color=fff`} alt="profile" />
                            </a>
                        </div>
                        <div className="profile-card-content d-flex flex-wrap flex-xxl-nowrap justify-content-center justify-content-xxl-between flex-grow-1">
                            <ul className="profile-info-list mt-2 mt-xxl-0 w-50">
                                <li className="name_info"><span className="title">
                                    <a onClick={NavigatorHandler} className='PointerClass text-title'>{NotificationData.name}</a>
                                </span> <span className="value font--sm text-title">upvoted your photo</span><CreatedAtDateFormat date={NotificationData.notification_createdAt} /></li>
                                <li className="mb-1 location_info"><span className="value font--sm ">{address}</span></li>
                                {NotificationData.camera_brand && NotificationData.camera_brand !== "null" && <li className="d-none d-sm-block camera_info"><span className="title ">Camera: </span> <span className="value">{NotificationData.camera_brand}</span></li>}
                                {genresString && <li className="d-none d-sm-block genres_info"><span className="title ">Genres: </span> <span className="value">{genresString}</span></li>}
                            </ul>
                            <span className="d-none d-sm-inline-flex" style={{ margin: "auto 0px" }}>
                                <FollowAndThanksButton
                                    usertoken={usertoken}
                                    ThanksFor={'thanks_on_upvote'}
                                    NotificationData={NotificationData}
                                    loginUserid={loginUserid}
                                />
                            </span>
                            <a className="comment-photo d-none d-sm-inline-flex" onClick={OtheruserProfileNavigateHandler}>
                                <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="d-block" />
                            </a>
                        </div>
                    </div>
                </div>
                <ul className="text-start profile-info-list d-sm-none">
                    {NotificationData.camera_brand && NotificationData.camera_brand !== "null" && <li className="camera_info"><span className="title ">Camera: </span> <span className="value">{NotificationData.camera_brand}</span></li>}
                    {genresString && <li className="genres_info"><span className="title ">Genres: </span> <span className="value">{genresString}</span></li>}
                </ul>
                <div className="d-flex d-sm-none flex-wrap flex-xl-nowrap justify-content-sm-end gap-2 gap-xl-3 btn--group">
                    <FollowAndThanksButton
                        usertoken={usertoken}
                        ThanksFor={'thanks_on_upvote'}
                        NotificationData={NotificationData}
                        loginUserid={loginUserid}
                    />
                </div>
                <a className="comment-photo d-inline-flex d-sm-none" onClick={OtheruserProfileNavigateHandler}>
                    <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="d-block" style={{ marginTop: "10px" }} />
                </a>
            </div>

        </>
    )
}

export default UpVoteNotification