

export const cameraTypes = [

    {

        value: 'Canon',
        displayText: 'Canon'

    },
    {

        value: 'Olympus',
        displayText: 'Olympus'

    },

    {

        value: 'Fuji',
        displayText: 'Fuji'

    },

    {

        value: 'Pentax',
        displayText: 'Pentax'

    },

    {

        value: 'Leica',
        displayText: 'Leica'

    },

    {

        value: 'Sony',
        displayText: 'Sony'

    },

    {

        value: 'Lumix',
        displayText: 'Lumix'

    },
    {

        value: 'Smartphone',
        displayText: 'Smartphone'

    },

    {

        value: 'Nikon',
        displayText: 'Nikon'

    },

    {

        value: 'other',
        displayText: 'Other'

    },





]

export const q1List = [
    {

        value: 'A professional',


    },
    {

        value: 'A hobbyist having fun',


    },
    {

        value: "I am still learning",


    },
    {

        value: 'I post on social media only',


    },
    {

        value: 'I just like to look',


    },
    {

        value: "I'm only here supporting a friend",


    },

]

export const q2List = [
    {

        value: '1',


    },
    {

        value: '2',


    },
    {

        value: "3",


    },
    {

        value: '4',


    },
    {

        value: '5',


    },
    {

        value: "6",


    },
    {

        value: "7",


    },
    {

        value: "8",


    },
    {

        value: "9",


    },
    {

        value: "10",


    },


]

export const q5List = [
    {

        value: 'Discover Photographers',


    },
    {

        value: 'Inspiration',


    },
    {

        value: "Exposure For My Photos",


    },
    {

        value: 'Meet Other Photographers',


    },
    {

        value: 'Get Feedback',


    },
    {

        value: "Something Else",


    },
    {

        value: "Grow My Social Media",


    },
    {

        value: "Tips From Others",


    },
    {

        value: "Improve My Photography",


    },
    {

        value: "Win Cool Stuff",


    },




]
export const WhyIamhereList = [
    {
        value: 'Discover Photographers',
    },
    {
        value: 'Exposure For My Photos',
    },
    {
        value: 'Get Feedback',
    },
    {
        value: 'Grow My Social Media',
    },
    {
        value: 'Improve My Photography',
    },
    {
        value: 'Inspiration',
    },
    {
        value: 'Meet New People',
    },
    {
        value: 'Tips From Others',
    },
    {
        value: 'Something Else',
    },
    {
        value: 'Win Cool Stuff',
    },
]

export const GenresList = [

    {

        value: 'Abstract',
        id: "Abstract1",


    },
    {

        value: 'Landscape',
        id: 'Landscape1',


    },

    {

        value: 'Aerial',
        id: 'Aerial1',


    },

    {

        value: 'Website',
        id: 'Website1',


    },

    {

        value: 'Astro',
        id: 'Astro1',


    },

    {

        value: 'Nature',
        id: 'Nature1',


    },

    {

        value: 'Documentary',
        id: 'Documentary1',


    },
    {

        value: 'Portrait',
        id: 'Portrait1',


    },

    {

        value: 'Drone',
        id: 'Drone1',


    },

    {

        value: 'Product',
        id: 'Product1',


    },

    {

        value: 'Events',
        id: 'Events1',


    },

    {

        value: 'Sport',
        id: 'Sport1',


    },

    {

        value: 'Art',
        id: 'Art1',


    },
    {

        value: 'Street',
        id: 'Street1',


    },
    {

        value: 'Food',
        id: 'Food1',


    },
    {

        value: 'Travel',
        id: 'Travel1',


    },
    {

        value: 'Other',
        id: 'Other1',


    },


]



export const photoUploadQ3List = [
    {

        value: 'Balance',
        id: "Balance2",


    },
    {

        value: 'Composition',
        id: 'Composition2',


    },

    {

        value: 'Color',
        id: 'Color2',


    },

    {

        value: 'Layering',
        id: 'Layering2',


    },

    {

        value: 'Light and Exposure',
        id: 'Light and Exposure2',


    },

    {

        value: 'Shadows',
        id: 'Shadows2',


    },

    {

        value: 'Story',
        id: 'Story2',


    },
    {

        value: 'Subject',
        id: 'Subject2',


    },

    {

        value: 'Technical Settings',
        id: 'Technical Settings2',


    },

    {

        value: 'The Edit',
        id: 'The Edit2',


    },

    {

        value: 'General Feedback',
        id: 'General Feedback2',


    },

    {

        value: 'I am not looking for feedback',
        id: 'I am not looking for feedback2',


    },


]


export const HowYouHeardAboutBigPhotoList = [
    {

        value: 'A friend told me',
        id: 'friend'


    },
    {

        value: 'Blog or Publication',
        id: 'Blog'

    },
    {

        value: "Newsletter",
        id: 'Newsletter'

    },
    {

        value: 'Search Engine',
        id: 'Engine'

    },
    {

        value: 'Social Media',
        id: 'Social'

    },
    {

        value: "Something Else",
        id: 'Something'

    },

]



export const GoodAt = [
    {
        value: "Abstract",
        id: "Abstract2"
    },
    {
        value: "AI",
        id: "AI2"
    },
    {
        value: "Animals",
        id: "Animals2"
    },
    {
        value: "Anticipation",
        id: "Anticipation2"
    },
    {
        value: "Architecture",
        id: "Architecture2"
    },
    {
        value: "Black & White",
        id: "BlackAndWhite2"
    },
    {
        value: "Camera Settings",
        id: "CameraSettings2"
    },
    {
        value: "Composition",
        id: "Composition2"
    },
    {
        value: "Editing",
        id: "Editing2"
    },
    {
        value: "Events",
        id: "Events2"
    },
    {
        value: "Exposure",
        id: "Exposure2"
    },
    {
        value: "Fast Shutter",
        id: "FastShutter2"
    },
    {
        value: "Film",
        id: "Film2"
    },
    {
        value: "Fstop",
        id: "Fstop2"
    },
    {
        value: "Getting Smiles",
        id: "GettingSmiles2"
    },
    {
        value: "Journalism",
        id: "Journalism2"
    },
    {
        value: "Landscape",
        id: "Landscape2"
    },
    {
        value: "Lighting",
        id: "Lighting2"
    },
    {
        value: "Macro",
        id: "Macro2"
    },
    {
        value: "Meeting People",
        id: "MeetingPeople2"
    },
    {
        value: "Nude",
        id: "Nude2"
    },
    {
        value: "Pets",
        id: "Pets2"
    },
    {
        value: "Portraits",
        id: "Portraits2"
    },
    {
        value: "Printing",
        id: "Printing2"
    },
    {
        value: "Product Photos",
        id: "ProductPhotos2"
    },
    {
        value: "Slow ISO",
        id: "SlowISO2"
    },
    {
        value: "Social Media",
        id: "SocialMedia2"
    },
    {
        value: "Storytelling",
        id: "Storytelling2"
    },
    {
        value: "Street",
        id: "Street2"
    },
    {
        value: "Teaching",
        id: "Teaching2"
    },
    {
        value: "Travel",
        id: "Travel2"
    },
    {
        value: "Underwater",
        id: "Underwater2"
    },
    {
        value: "Weddings",
        id: "Weddings2"
    },
    {
        value: "White Balance",
        id: "WhiteBalance2"
    }
];


export const MeetFor = [
    {
        value: "Collaboration",
        id: "Collaboration3"
    },
    {
        value: "Photowalks",
        id: "Photowalks3"
    },
    {
        value: "Travel",
        id: "Travel3"
    },
    {
        value: "Conversation",
        id: "Conversation3"
    },
    {
        value: "Friendship",
        id: "Friendship3"
    },
    {
        value: "Reviewing My Work",
        id: "ReviewingMyWork3"
    },
    {
        value: "Learning",
        id: "Learning3"
    },
    {
        value: "Networking",
        id: "Networking3"
    },
    {
        value: "Job Opportunities",
        id: "JobOpportunities3"
    },
    {
        value: "Dating/Romance",
        id: "DatingRomance3"
    },
    {
        value: "Sharing Experiences",
        id: "SharingExperiences3"
    },
    {
        value: "Sharing Gear",
        id: "SharingGear3"
    },
    {
        value: "Visiting Me",
        id: "VisitingMe3"
    },
    {
        value: "Grab Coffee",
        id: "GrabCoffee3"
    },
    {
        value: "Modeling",
        id: "Modeling3"
    },
    {
        value: "Visit Exhibits",
        id: "VisitExhibits3"
    }
];

export const DayList = [
    { value: 'Monday', id: "Monday1" },
    { value: 'Tuesday', id: "Tuesday1" },
    { value: 'Wednesday', id: "Wednesday1" },
    { value: 'Thursday', id: "Thursday1" },
    { value: 'Friday', id: "Friday1" },
    { value: 'Saturday', id: "Saturday1" },
    { value: 'Sunday', id: "Sunday1" },
  ];