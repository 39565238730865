import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { decryptUserData } from '../../utils/encryptedData'
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { useNavigate } from "react-router-dom";
import { SocketAction } from "../../Store/SocketSlice";
import { CheckNewNotification } from "../../utils/API/NotificationApi";
import { NavLink } from 'react-router-dom';
import { PayWallAction } from "../../Store/PayWallSlice";
import { getUserPaymentStatus } from "../../utils/API/PaymentApi";
// import SamCartCheckout from "../samcart";
import CheckoutForm from "../Strip";


const LoginHeader = ({ socket }) => {
  let [MobileMenuState, setMobileMenuState] = useState(false)
  let [OverlayState, setOverlayState] = useState(false)
  let [ActiveBell, setActiveBell] = useState(false)
  let [ReloadNotification, setReloadNotification] = useState(false)
  let [MonthlyGiveawayDropDown, setMonthlyGiveawayDropDown] = useState(false)
  let [MobileProfileSettingsState, setMobileProfileSettingsState] = useState(false)
  // let [isPaid, setisPaid] = useState(false)

  let dispatch = useDispatch()
  let navigate = useNavigate();

  let isSamCardSideBarOpen = useSelector(state => state.PayWall.isSamCardSideBarOpen)

  useEffect(() => {
    let getUserPaymentStatusApi = async () => {
      try {
        let response = await getUserPaymentStatus(usertoken)
        let status = response.data.data.isPaid
        dispatch(PayWallAction.setUserPaymentStatus(status))
        
      }
      catch (error) { console.log(error) }

    }
    getUserPaymentStatusApi()

  }, []);

  // <---------------- decrypted data ------------->

  const encryptedUserData = localStorage.getItem('Data');
  let decryptedUserData = decryptUserData(encryptedUserData);
  let UserPhoto = decryptedUserData.Display_UserSocailPhoto;
  let display_name = decryptedUserData.useDisplay_name;
  let usertoken = decryptedUserData.token


  // <-------------------------------------------->

  let PhotoUploadModalHandler = () => {
    dispatch(ModalAction.setPhotoUploadModalState(true))
    dispatch(ModalAction.setPhotoUploadType("hunted_photo"))
    dispatch(ModalAction.setisQueued({ queue: false }))
  }

  // <-------------------------------------------->


  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setActiveBell(true)
      setReloadNotification(true)
    });

  }, [socket]);


  useEffect(() => {

    let NotificationApi = async function () {
      setActiveBell(false)
      try {

        let headers = {
          'Authorization': `Bearer ${usertoken}`,
          'Content-Type': 'application/json'
        }
        const response = await CheckNewNotification(usertoken);


        setActiveBell(response.data.data[0].is_notification)

      } catch (error) {


      }

    }
    NotificationApi();

  }, []);

  let ReloadNotif = useSelector(state => state.Socket.ReloadGetNorificationApi)

  let BellHandler = () => {
    setActiveBell(false)
    window.scrollTo(0, 0)
    if (ReloadNotification) {
      dispatch(SocketAction.setReloadGetNorificationApi(!ReloadNotif))
    }
    setReloadNotification(false)

  }

  let MEApiState = useSelector(state => state.Modal.MeApiState)


  if (MEApiState) {
    decryptedUserData = decryptUserData(encryptedUserData);
  }



  // <-------------------------------------------->
  let MenuHandler = () => {
    setMobileMenuState(true)
    setOverlayState(true)
  }

  let MenuCloseHandler = () => {
    setMobileMenuState(false)
    setOverlayState(false)
  }

  let monthlyGiveAwayHandler = () => {
    setMonthlyGiveawayDropDown(!MonthlyGiveawayDropDown)
  }

  // <-------------------------------------------->
  let profileSettingsHandler = () => {
    scrollToTop()
    setMobileProfileSettingsState(!MobileProfileSettingsState)
  }

  const scrollToTop = () => {
    setMobileMenuState(false)
    setOverlayState(false)
    setMobileProfileSettingsState(false)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  let websiteLogoHandler = () => {
    // dispatch(GenericAction.setLogoClickState(true))
    navigate('/')
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }





  return (
    <>

      {OverlayState && <div className="overlay " onClick={MenuCloseHandler}></div>}


      <header className="site-header with_login" id="site-header">
        {/* <!-- 
      css:
      without_login
      with_login
     --> */}
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="site_menu d-flex align-items-center flex-wrap">
                <div className="menu_bar">
                  <Link >
                    <img className="forDesktop" src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                    <img className="forMobile" onClick={MenuHandler} src="./assets/img/icons/menu-bar.svg" alt="Menu Bar" />
                  </Link>
                  {/* <!-- Hamburger Menu --> */}
                  <nav className="side_menu d-none d-md-block">
                    <ul>
                      <li>
                        <Link to={'/about'} onClick={scrollToTop}>About</Link>
                      </li>
                      <li>
                        <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                      </li>
                      {/* <li>
                        <Link >This Month’s Prize </Link>
                      </li> */}

                      {/* <li className="has-dropdown">
                        <a >Monthly Giveaway 1</a>
                        <ul className="submenu">
                          <li>
                            <Link to={"/new-giveaway"} onClick={scrollToTop}>This Month</Link>
                          </li>
                          <li className="active">
                            <Link to={"/previousgiveaways"}>Previous Months</Link>
                          </li>
                        </ul>
                      </li> */}

                      <li>
                        <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
                      </li>

                      <li>
                        <Link to={'/feedback'} onClick={scrollToTop}>Good Feedback</Link>
                      </li>
                      {/* <li>
                        <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                      </li> */}
                      <li>
                        <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
                      </li>
                      {/* <li>
                        <Link to={'/monthly-townhall'} onClick={scrollToTop}>Events</Link>
                      </li> */}
                      <li>
                        <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="site_logo">
                  <Link to={'/'} onClick={websiteLogoHandler}>
                    <img
                      src="./assets/img/logo/logo-black.png"
                      alt="Site Logo"
                    />
                  </Link>
                </div>
                <nav className="main_menu d-none d-md-block">
                  <ul>
                    <li>
                      <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
                    </li>
                    {/* <li>
                      <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
                    </li> */}
                    <li>
                      <Link to={'/topten'} onClick={scrollToTop}>Weekly Top 10</Link>
                    </li>

                    <li className="has-dropdown" style={{ position: "relative" }}>
                      <a >Monthly Giveaway </a>
                      <ul className="submenu">
                        <li>
                          <Link to={"/new-giveaway"} onClick={scrollToTop}>This Month</Link>
                        </li>
                        <li >
                          <Link to={"/previousgiveaways"} onClick={scrollToTop} >Previous Months</Link>
                        </li>
                      </ul>
                    </li>



                    <li>
                      <Link to={'/monthly-townhall'} onClick={scrollToTop}>Events</Link>
                    </li>
                  </ul>
                </nav>
                {/* <!-- without login --> */}
                {/* <!-- <div className="menu_right without_login">
              <div className="button sign_in">
                <Link to="#" className="btn btn-white">Sign in</Link>
              </div>
              <div className="button join_now">
                <Link to="#" className="btn">Join now</Link>
              </div>
            </div> -->
						<!-- !without login --> */}

                {/* <!-- with logged in --> */}
                <div className="menu_right with_logged_in">
                  <div className="button">
                    {/* <input type="file" onChange={imgHandler} accept=".jpg, .jpeg, .png"></input> */}
                    {/* <button >post img</button> */}
                    <button
                      onClick={PhotoUploadModalHandler}
                      className="btn d-none d-lg-inline-block" >
                      Hunt My Photo
                    </button>
                    <Link to="#" className="btn plus d-lg-none" onClick={PhotoUploadModalHandler}>
                      <img src="./assets/img/icons/plus.svg" alt="img" />
                    </Link>
                  </div>
                  <div className="notification">
                    <Link to={'/activity'} className={`${!ActiveBell ? "inline-style " : ''}`} onClick={BellHandler}>
                      <img src="./assets/img/icons/bell.svg" alt="Bell" className={`${ActiveBell ? "bump" : ''}`} />
                    </Link>
                  </div>
                  <div className="user">
                    <Link to="/profile" onClick={profileSettingsHandler}
                      style={{
                        backgroundImage: `url(${UserPhoto || ""})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "50%",
                        cursor: "pointer",

                      }}>
                      {!UserPhoto && <img style={{ borderRadius: "50%" }}
                        src={`https://ui-avatars.com/api/?name=${display_name}&size=40&rounded=true&background=01655a&color=fff`}

                        alt="User Pic"
                      />}
                    </Link>
                    {(window.innerWidth >= 600 ? true : MobileProfileSettingsState) && <div className="profile_settings">
                      <ul onClick={scrollToTop}>
                        <li>
                          <NavLink to="/profile" activeClassName="active">
                            Profile
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/people" activeClassName="active">
                            People
                          </NavLink>
                        </li>
                        <li>
                           <NavLink to="/queue" activeClassName="active">
                            Queue
                          </NavLink>
                            
                        </li>
                        <li>
                          <NavLink to="/club-page" activeClassName="active">
                            Participate
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/edit-profile" activeClassName="active">
                            Account
                          </NavLink>
                        </li>
                        {/* Uncomment the line below to add a logout link */}
                        {/* <li><NavLink to="/" onClick={logoutHandler}>Logout</NavLink></li> */}
                      </ul>
                    </div>}
                  </div>
                  {/* <button style={{ backgroundColor: "#ffd621", color: 'black', border: 'none', padding: "10px", borderRadius: "50px", marginLeft: "10px" }} onClick={logout}> Logout </button> */}
                </div>
                {/* <!-- !logged in --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Hamburger mobile Menu --> */}
        <nav className={`side_menu mobile d-md-none ${MobileMenuState ? "open" : ""}`}>
          <div className="side_menu_top">
            <Link className="close" onClick={MenuCloseHandler}>
              <img src="./assets/img/icons/close.svg" alt="img" />
            </Link>
          </div>
          {/* } */}
          <ul  >
            <li >
              <Link to={'/about'} onClick={scrollToTop}>About</Link>
            </li>
            <li >
              <Link to={'/how-works'} onClick={scrollToTop}>How It Works</Link>
            </li>
            {/* <li>
              <Link  >This Month’s Prize </Link>
            </li> */}


            <li className="has-dropdown">
              <a onClick={monthlyGiveAwayHandler}>Monthly Giveaway </a>
              <ul className="submenu" style={{ display: MonthlyGiveawayDropDown ? 'block' : 'none' }}>
                <li>
                  <Link to={"/new-giveaway"} onClick={scrollToTop}>This Month</Link>
                </li>
                <li >
                  <Link to={"/previousgiveaways"} onClick={scrollToTop} >Previous Months</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to={"/topten"} onClick={scrollToTop}>Weekly Top 10</Link>
            </li>
            <li>
              <Link to={'/new-members'} onClick={scrollToTop}>New Members</Link>
            </li>
            <li>
              <Link to={'/feedback'} onClick={scrollToTop}>Good Feedback</Link>
            </li>
            {/* <li>
              <Link to={'/meet-terry'} onClick={scrollToTop}>A Mouse?</Link>
            </li> */}
            <li>
              <Link to={'/contact'} onClick={scrollToTop}>Contact Us</Link>
            </li>
            <li>
              <Link to="https://bigphotohunt.buzzsprout.com" target='_blank'>Podcast</Link>
            </li>
            <li>
              <Link to={'/monthly-townhall'} onClick={scrollToTop}>Events</Link>
            </li>
            <li>
              <Link to={'/beta'} onClick={scrollToTop}>Beta</Link>
            </li>
          </ul>
        </nav>
      </header>

      {/* <SamCartCheckout isVisible={isSamCardSideBarOpen} /> */}
      {/* <CheckoutForm/> */}
    </>
  );
};

export default LoginHeader;
