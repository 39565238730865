import axios from "axios";
import axiosInstance from '../Services/AxiosInstance';



export const Loginapi = async (lowercaseEmail, userPassword,) => {
    try {

        const payload = {

            emailOrUsername: lowercaseEmail,
            password: userPassword,
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, payload);

        return response;
    } catch (error) {

        throw error;
    }
};




export const fetchUserInfo = async (email) => {
    try {
        const payload = {
            email: email,
        };

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/Me`, payload);

        return response;
    } catch (error) {

        throw error;
    }
};


export const fetchOtherUserInfo = async (usertoken, userID) => {
    try {
        const payload = {
            "userID": userID,
        };

        let headers = {
            'Authorization': `Bearer ${usertoken}`,
            'Content-Type': 'application/json'
        }

        const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/user/get_user_profile`, payload, { headers });

        return response;
    } catch (error) {

        throw error;
    }
};