import React from 'react'
import FollowAndThanksButton from './FollowAndThanksButton'
import { useNavigate } from 'react-router-dom';
import { AES } from 'crypto-js';
import CreatedAtDateFormat from './CreatedAtDateFormat';

const ThanksForCommentLikeNotification = ({ NotificationData, usertoken, loginUserid }) => {

    let navigate = useNavigate()
    let NavigatorHandler = () => {
        let userData = {
            PhotoID: "",
            UserID: NotificationData.sender_id,
        }
        const secretKey = process.env.REACT_APP_SECRET_KEY;
        const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
        navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        window.scrollTo(0, 0)
    }

    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();

        if (NotificationData.receiver_id == NotificationData.photo_owner_id) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: NotificationData.photo_id,
                UserID: NotificationData.photo_owner_id,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        }

        window.scrollTo(0, 0)
    }
    return (
        <>
            <div className="profile-card style--two">
                <div className="profile-card-inner">
                    <div className="profile-card-top-content mb-0">
                        <div className="profile-card-thumb rounded-0">
                            <span className='PointerClass' onClick={NavigatorHandler}>
                                <img src={NotificationData.profile_pic ? NotificationData.profile_pic : `https://ui-avatars.com/api/?name=${NotificationData.name}&size=40&rounded=true&background=01655a&color=fff`} alt="profile" />
                            </span>
                        </div>
                        <div className="profile-card-content flex-column flex-md-row align-items-start align-items-xl-center">
                            <ul className="profile-info-list">
                                <li className="name_info"><span className="title"><span onClick={NavigatorHandler} className="text-title PointerClass">{NotificationData.name}</span> </span> <span className="value font--sm text-title"> Thanked you for liking their comment.</span> <CreatedAtDateFormat date={NotificationData.notification_createdAt}/></li>
                                <li className="mt-1 d-none d-sm-block lh-20px"><span className="value">{`“${NotificationData.comment_text}”`}</span></li>
                            </ul>
                            <div className="d-sm-flex d-none flex-column-reverse flex-md-row flex-xl-nowrap justify-content-xl-end align-items-md-center flex-grow-1 flex-shrink-0 align-items-xl-center gap-2">
                                <FollowAndThanksButton
                                    ThanksFor={'thanks_on_upvote_comment'}
                                    thanksBtnHidden={true}
                                    usertoken={usertoken}
                                    NotificationData={NotificationData}
                                    loginUserid={loginUserid}
                                />
                                <span className='PointerClass' onClick={OtheruserProfileNavigateHandler}><img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="comment-photo" /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-3 gap-3 d-sm-none justify-content-between">
                    <p className="text-start mb-0 lh-20px" >{`“${NotificationData.comment_text}”`}</p>
                    <span className='PointerClass' onClick={OtheruserProfileNavigateHandler}><img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_250,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="comment-photo" style={{ width: 44, height: 44, objectFit: 'cover' }} /></span>
                </div>
                <div className="d-sm-none desFollow--btn">
                    <FollowAndThanksButton
                        ThanksFor={'thanks_on_upvote_comment'}
                        thanksBtnHidden={true}
                        usertoken={usertoken}
                        NotificationData={NotificationData} /></div>


            </div>


        </>
    )
}

export default ThanksForCommentLikeNotification