import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { formatDate } from '../../utils/Formatteddate';
import { FollowAction } from '../../Store/FollowSlice';
import { Link } from 'react-router-dom';
import { ShortAddress } from '../../utils/Address';
import { followUser } from '../../utils/API/FollowApi';
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';
import { FollowerImagesApi } from '../../utils/API/FollowerImgModalApi';
import CustomLoader from '../Skeleton/CustomLoader';
import MainImageSkeleton from '../Skeleton/MainImageSkeleton';
import { AES } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

const FollowerImgModal = () => {

    let [ThumbsSwiper, setThumbsSwiper] = useState(null)
    let [PhotoList, setPhotoList] = useState([])
    let [UserInfo, setUserInfo] = useState('')
    let [genresString, setgenresString] = useState('')
    let [follow, setfollow] = useState('')
    let [IsMobile, setIsMobile] = useState(false)
    const [slidesPerView, setSlidesPerView] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (window.innerWidth <= 500) {
            setIsMobile(true)
        }
    }, [])

    let navigate = useNavigate();
    let OtheruserProfileNavigateHandler = (event) => {
        event.preventDefault();

        if (Userobject.LoginUserId == Userobject.User_ID) {
            navigate("/profile");
        } else {
            let userData = {
                PhotoID: '',
                UserID: Userobject.User_ID,
            }
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
            navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);
        }
        dispatch(FollowAction.setFollowerimgModalOpen(false))
        window.scrollTo(0, 0)
    }

    // < --------Socket ------->


    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(UserInfo.id))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->

    let dispatch = useDispatch()
    let Userobject = useSelector(state => state.Follow.FollowerImgmodalUserInfo)

    let FollowHandler = async (event) => {
        event.preventDefault();
        setfollow(!follow)

        if (!follow) {

        }
        try {
            const response = await followUser(UserInfo.id, !follow, Userobject.usertoken, Userobject.LoginUserId, SendNotificationFn);

        } catch (error) {
        }
    }







    useEffect(() => {
        let PhotoListHandler = async (event) => {
            try {

                const response = await FollowerImagesApi(Userobject.User_ID, Userobject.usertoken)
                setPhotoList(response.data.data[0].photos)
                setUserInfo(response.data.data[0])
                setgenresString(response.data.data[0].genres_list ? Object.values(response.data.data[0].genres_list).slice(0, 3).join(", ") : "")
                setfollow(response.data.data[0].is_follow)
            } catch (error) {

            }

        }
        PhotoListHandler()
    }, [Userobject])



    const swiperRef = useRef(null);
    useEffect(() => {

        // Check if swiperRef is available and LightBoxArr has data
        if (swiperRef.current && PhotoList) {
            // Find the index of the slide with ID 3 in PhotoList
            const index = PhotoList.findIndex(item => item.id === Userobject.photo_id);

            // Check if the index is found
            if (index !== -1) {
                // Activate the slide with the found index

                swiperRef.current.swiper.slideTo(index);
            }
        }
    }, [Userobject.photo_id, PhotoList]);



    useEffect(() => {
        // Update the number of slides per view based on the screen width
        const handleResize = () => {
            const newSlidesPerView = window.innerWidth <= 767 ? 5 : 10;
            setSlidesPerView(newSlidesPerView);
        };

        // Initial setup
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const modalRef = useRef(null);



    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dispatch(FollowAction.setFollowerimgModalOpen(false));
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    return (
        <>
            <div className="backdrop" />
            <div className="light-box"
            >
                <div className="modal fade show" id="exampleModalNew1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }} >
                    <div className="modal-dialog modalAnimation" ref={modalRef}>
                        <div className="modal-content">
                            <div className="modal-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', border: 0, padding: '0px 0' }}>
                                <button type="button" className="btn-close opacity-100" data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(FollowAction.setFollowerimgModalOpen(false)) }} />
                            </div>
                            {UserInfo ? <div className="modal-body">
                                {UserInfo && <div className="profile-card">
                                    <div className="profile-card-inner">
                                        <div className="profile-card-top-content">
                                            <div className="profile-card-thumb">
                                                <Link
                                                    onClick={OtheruserProfileNavigateHandler}
                                                    className='memberUser_img'
                                                    style={{
                                                        backgroundImage: `url(${UserInfo.profile_pic || ""})`,
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        borderRadius: "50%",
                                                        cursor: "pointer",
                                                        display: "block",
                                                        height: "60px",
                                                        width: "60px",
                                                    }}
                                                >
                                                    {!UserInfo.profile_pic && <img src={`https://ui-avatars.com/api/?name=${UserInfo.name}&size=60&rounded=true&background=01655a&color=fff`} />}
                                                </Link>
                                            </div>
                                            <a >
                                                <div className="profile-card-content">
                                                    <ul>
                                                        {UserInfo.camera_brand && UserInfo.camera_brand !== "null" && <li>
                                                            <span className="title">Camera: </span>
                                                            <span className="value">{UserInfo.camera_brand}
                                                            </span>
                                                        </li>
                                                        }
                                                        {genresString && <li>
                                                            <span className="title">Genres: </span>
                                                            <span className="value">{genresString}</span>

                                                        </li>}
                                                        {UserInfo.photo_assistance && UserInfo.photo_assistance !== "null" && <li className="d-sm-block d-none">
                                                            <span className="title">Why I'm Here: </span>
                                                            <span className="value">{UserInfo.photo_assistance}
                                                            </span>
                                                        </li>}
                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="profile-card-bottom-content">
                                            <a >
                                                <div className="left">
                                                    <h6 className="name" onClick={OtheruserProfileNavigateHandler}>{UserInfo.name}</h6>
                                                    <p className="font--sm mb-0">{ShortAddress(UserInfo?.state, 16)} | {formatDate(UserInfo?.join_date)}</p>
                                                </div>
                                            </a>
                                            <div className="right">
                                                {!follow ? <button className="btn btn--base  follow--unfollow" onClick={FollowHandler}>Follow</button> :
                                                    <button className="btn btn--base btn--unfollow follow--unfollow" onClick={FollowHandler}>Following</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <Swiper
                                    ref={swiperRef}
                                    style={{
                                        '--swiper-navigation-color': '#fff',
                                        '--swiper-pagination-color': '#fff',
                                    }}
                                    spaceBetween={10}
                                    navigation={PhotoList.length > 1}
                                    thumbs={{ swiper: ThumbsSwiper }}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2"

                                >
                                    {PhotoList?.map((data, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="slider-inner">
                                                {isLoading && <span className="FollowModalImgLoader"></span>}
                                                <img
                                                    src={!IsMobile ? `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_1200,f_auto,q_auto/Resized_Photos/${data.compressedImageId}` : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_700,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`}
                                                    alt='img'
                                                    onLoad={() => setIsLoading(false)} // Set isLoading to false when the image is loaded
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}


                                </Swiper>
                                <Swiper
                                    // ref={swiperRef}
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={slidesPerView}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="swiper mySwiper"

                                >
                                    {PhotoList?.map((data, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={`https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_220,f_auto,q_auto/Resized_Photos/${data.compressedImageId}`} alt='img' />
                                        </SwiperSlide>
                                    ))}

                                </Swiper>
                            </div> : <CustomLoader />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FollowerImgModal