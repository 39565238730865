import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"

import { decryptUserData } from '../../utils/encryptedData'
import { useDispatch } from "react-redux";
import { ModalAction } from "../../Store/Modal-Slice";
import { ShortAddress } from '../../utils/Address';

import useImageLoader from '../../Hooks/useImageLoader';
import { AES } from 'crypto-js'
import { useSelector } from 'react-redux'
import { followUser } from '../../utils/API/FollowApi'
import { SocketAction } from '../../Store/SocketSlice'
import amplitude from 'amplitude-js';



const MembersList = (props) => {
  let [Isfollow, setIsfollow] = useState(props.isfollow)
  let [Iswelcome, setIswelcome] = useState(props.iswelcome)



  let dispatch = useDispatch()

  // <---------------- decrypted data ------------->
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  let usertoken = decryptedUserData.token
  let isLoggedIn = decryptedUserData.isLoggedIn
  let Userid = decryptedUserData.userid
  // <-------------------------------------------->

  let openJoinNowModalHandler = () => {
    dispatch(ModalAction.setPopupModalMessage({
      "joinModalHeading": "",
      "popupHeading": "You’re In!",
      "popupMessage": "Click on Continue below to get you back to the photo you were interested in. Check your email too. It's where we'll send you each week's most upvoted photos.",
      photoID: "",
      userID: props.userId,
    }))
    dispatch(ModalAction.setJoinNowModalState(true))
  }

  // <--------------------------->

  let navigate = useNavigate();

  let OtheruserProfileNavigateHandler = (event) => {
    event.preventDefault();



    if (Userid == props.userId) {
      navigate("/profile");
    } else {
      let userData = {
        PhotoID: "",
        UserID: props.userId,
      }
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const encryptedUserData = AES.encrypt(JSON.stringify(userData), secretKey).toString();
      navigate(`/otherusers-profile?userdata=${encodeURIComponent(encryptedUserData)}`);

    }

    window.scrollTo(0, 0)
  }

  // < --------Socket ------->

  let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

  let SendNotificationFn = () => {
    dispatch(SocketAction.setSocketReceiverUserID(props.userId))
    dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
  }
  // <-------------------------------------------->


  ///address
  let address = ShortAddress(props.userLocation, 16);

  

  useEffect(() => {
    setIsfollow(props.isfollow);
    setIswelcome(props.iswelcome);

  }, [props.isfollow, props.iswelcome]);




  let FollowHandler = async (event) => {
    event.preventDefault();

    setIsfollow(!Isfollow)
    if (!Isfollow) {
      SendNotificationFn()
    }
    try {
      await followUser(props.userId, !Isfollow, usertoken, props.loginUserid,SendNotificationFn);
    } catch (error) {
    }
  }


  let WelcomeHandler = async (event) => {
    event.preventDefault();


    setIswelcome(!Iswelcome)

    SendNotificationFn()
    try {
      const payload = {
        "userID": props.userId,
        "is_welcome": !Iswelcome
      };
      let headers = {
        'Authorization': `Bearer ${usertoken}`,
        'Content-Type': 'application/json'
      }
      await axios.post(`${process.env.REACT_APP_BASE_URL}/user/welcome`, payload, { headers });

      amplitude.getInstance().logEvent('Welcome - Hi', { UserID: props.loginUserid });



    } catch (error) {

    }

  }


  const genresString = props.genreslist ? Object.values(props.genreslist).slice(0, 3).join(", ") : "";


  return (
    <>


      <div className="new_member">
        <div className="member_top">
          <div className="member_img">
            <Link
              onClick={isLoggedIn ? OtheruserProfileNavigateHandler : openJoinNowModalHandler}
              className='memberUser_img'
              style={{
                backgroundImage: `url(${props.userPhoto || ""})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              {!props.userPhoto && <img src={`https://ui-avatars.com/api/?name=${props.Name}&size=40&rounded=true&background=01655a&color=fff`} alt='img'/>}
            </Link>
          </div>
          <ul className="other_info">
            <li><strong>Camera:</strong>{' ' + props.camerabrand}</li>
            <li><strong>Genres:</strong> {genresString}</li>
          </ul>
        </div>
        <div className="member_bottom">
          <div className="user_info">
            <h6 className="name">
              <span className='PointerClass' onClick={isLoggedIn ? OtheruserProfileNavigateHandler : openJoinNowModalHandler}>{props.Name}</span></h6>
            <span className="address">{address}</span>
          </div>
          {props.loginUserid !== props.userId && <div className="member_follow" onClick={isLoggedIn ? FollowHandler : openJoinNowModalHandler}>
            {
              !Isfollow ? <a className="follow"><img src="./assets/img/icons/follow-black.svg" alt='img' />Follow</a>
                :
                <a className="following"><img src="./assets/img/icons/following.svg" alt='img' />Following</a>
            }
          </div>}
          {props.loginUserid !== props.userId && <div className="member_hi activated" >
            {Iswelcome ? <a className="default_hi"><img src="./assets/img/icons/default-hi.svg" alt='img' />Hi
              Sent</a> :
              <a className="active_hi"><img src="./assets/img/icons/hi.svg" alt='img' onClick={isLoggedIn ? WelcomeHandler : openJoinNowModalHandler} />Say
                Hi</a>}
          </div>}
        </div>
      </div>

      

    </>
  )
}

export default MembersList