import React, { useState, useEffect, useRef } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import GalleyPhotoUpload from './GalleyPhotoUpload';
import GalleryNOPhoto from './GalleryNOPhoto'
import { formatDate } from '../../utils/Formatteddate';
import { useDispatch } from 'react-redux';
import { ModalAction } from '../../Store/Modal-Slice';
import { loadGalleryPhotos } from '../../utils/API/GalleryApi';
import { useSelector } from 'react-redux';
import { GalleryAction } from '../../Store/Gallery-Slice';
import ManageGallery from './ManageGallery';
import { DeleteGalleryPhoto } from '../../utils/API/GalleryApi';
import { LightBoxAction } from '../../Store/LightboxSlice';
import amplitude from 'amplitude-js';
import MainGallery from './MainGallery';



const GalleryContainer = ({ usertoken, OtherUserGAllery, OtherUserID, ReloadGalleryPhotolist, UserName, UserPaymentStatus, OtherUserPaymentStatus }) => {

    let [Loader, setLoader] = useState(false)
    let [SelectedPhotoArray, setSelectedPhotoArray] = useState(false)
    let [IsDeleteApiRun, setIsDeleteApiRun] = useState(false)
    let [IsDeleteGalleryPhotoModal, setIsDeleteGalleryPhotoModal] = useState(false)
    let [GalleryFilter, setGalleryFilter] = useState({ sortingType: "upload_date", value: 'new' })
    let [Reload, setReload] = useState(true)
    let [EmptyGallery, setEmptyGallery] = useState(false)


    let dispatch = useDispatch()
    let Galleryfooterr = useRef();

    let AfterUploadPhoto = useSelector(state => state.Modal.StateAfterUploadPhoto)
    let GalleryUploadComponentState = useSelector(state => state.Gallery.GalleryUploadComponentState)
    let UploadedGalleryPhotos = useSelector(state => state.Gallery.SuccessfullyUploadedGalleryPhotos)
    let PlusBtnIsClick = useSelector(state => state.Gallery.ProfileInfoPlusBtnIsClick)
    let isViewGallery = useSelector(state => state.Gallery.ViewGallery)
    let IsManageGallery = useSelector(state => state.Gallery.IsManageGallery)
    // / <-------------------------------------------->



    let CheckDelBtnIsActive = (val) => {
        setSelectedPhotoArray(val)

    }

    const DelHandler = async () => {
        // Your delete logic here

        setIsDeleteApiRun(true)

        setLoader(true)
        try {
            let response = await DeleteGalleryPhoto(usertoken, SelectedPhotoArray);

            setIsDeleteGalleryPhotoModal(false)
            setLoader(false);
        } catch (error) {
            console.error(error);

        }
    };





    const setIsDeleteApiRunFalse = () => {
        // Your delete logic here

        setIsDeleteApiRun(false)
    };
    // <-------------------------------------------->

    // let GalleryUploadComponentHandler = () => {
    //     dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: true }))
    //     dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: false }))
    //     // dispatch(GalleryAction.setIsManageGallery(false))
    //     
    // }
    // <-------------------------------------------->

    let ManageGalleryHandler = () => {
        if (EmptyGallery) {
            return
        }
        dispatch(GalleryAction.setGalleryUploadComponentState({ open: false, BtnDisable: false }))
        if (!isViewGallery.mainGallery && !isViewGallery.manageGallery) {
            dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: true }))
        } else {
            dispatch(GalleryAction.setViewGallery({ mainGallery: !isViewGallery.mainGallery, manageGallery: !isViewGallery.manageGallery }))
        }

        // dispatch(GalleryAction.setIsManageGallery(true))

    }


    let PhotoUploadModalHandler = () => {
        // dispatch(ModalAction.setPhotoUploadModalState(true))
        // dispatch(ModalAction.setPhotoUploadType("gallery"))
        // dispatch(ModalAction.setisQueued({ queue: false }))

        dispatch(GalleryAction.setGalleryUploadComponentState({ open: true, BtnDisable: true }));
        dispatch(GalleryAction.setProfileInfoPlusBtnIsClick(true));
        dispatch(GalleryAction.setViewGallery({ mainGallery: false, manageGallery: false }));
        // dispatch(GalleryAction.setIsManageGallery(false))

    }


    let CheckPhotoListIsEmptyFn = (Boolean) => {

        setEmptyGallery(Boolean)
    }




    return (
        <>
            <div className="othersWorkContentWrap fade show active" id="otherWork" role="tabpanel">
                <div className="feed_content_heading d-flex gap-lg-3 align-items-center justify-content-between flex-wrap">
                    <div className='d-flex flex-column text-black ' style={{ width: '100%' }}>
                        <h3 className="title" style={{ cursor: "pointer", marginBottom: "5px" }} onClick={!OtherUserGAllery ? () => { dispatch(GalleryAction.setViewGallery({ mainGallery: true, manageGallery: false })) } : null}>{!OtherUserGAllery ? "My Personal Gallery" : `${UserName.split(' ')[0].substring(0, 10)}'s Personal Gallery`}</h3>
                        <p style={{ marginTop: '10px' }}>{`${!OtherUserGAllery ? "Add photos to this space to show Members your photography style." : `Photos that ${UserName.split(' ')[0].substring(0, 10)} is sharing with you to show their style.`}`}</p>
                    </div>
                    {!OtherUserGAllery && <>
                        <ul className="gallery_tools" >
                            <li className="tool" onClick={PhotoUploadModalHandler} >
                                <button
                                    disabled={GalleryUploadComponentState.BtnDisable}
                                    style={{
                                        border: 'none',
                                        backgroundColor: "transparent",
                                        filter: GalleryUploadComponentState.BtnDisable ? "opacity(0.5)" : "none",
                                        cursor: GalleryUploadComponentState.BtnDisable ? "not-allowed" : "pointer"
                                    }}
                                >
                                    <img src="./assets/img/icons/g-plus.svg" alt="Add" />
                                    <span className="tip"><span>Add</span></span>
                                </button>

                            </li>
                            {/* <li className="tool" onClick={PhotoUploadModalHandler}>
                                <img src="./assets/img/icons/g-hunt.svg" alt="img" style={{filter:"brightness(0.5)"}}/>
                                <span className="tip"><span>Hunt Photo</span></span>
                            </li> */}
                            <li className="tool">
                                <button
                                    onClick={SelectedPhotoArray?.length > 0 ? () => { setIsDeleteGalleryPhotoModal(true) } : null}
                                    style={{
                                        border: 'none',
                                        backgroundColor: "transparent",
                                        filter: SelectedPhotoArray?.length > 0 && isViewGallery.manageGallery ? "brightness(0.5)" : "opacity(0.5)",
                                        cursor: SelectedPhotoArray?.length > 0 && isViewGallery.manageGallery ? "pointer" : "not-allowed"
                                    }}>
                                    <img src="./assets/img/icons/g-trash.svg" alt="img" />
                                    <span className="tip"><span>Delete</span></span>
                                </button>

                            </li>
                            <li className="tool" >
                                <button onClick={ManageGalleryHandler} style={{
                                    border: 'none',
                                    backgroundColor: "transparent",
                                    filter: IsManageGallery ? "opacity(0.5)" : "none",
                                    cursor: IsManageGallery ? "not-allowed" : "pointer"
                                }}>
                                    <img src="./assets/img/icons/g-picture.svg" alt="img" />
                                    <span className="tip"><span>Manage</span></span>
                                </button>
                            </li>
                        </ul>
                        {/* {!IsManageGallery && !GalleryUploadComponentState.open && <ul className="feedFilter dropdown" >
                            <li className="activated" />
                            <img src="./assets/img/icons/arrow-down.svg" alt="img" />
                            <ul className="filter_lists" >
                                <li className="active">
                                    <a onClick={() => { GalleryFilterHandler({ sortingType: "upload_date", value: 'new' }) }}>upload_date New</a>
                                </li>
                                <li>
                                    <a onClick={() => { GalleryFilterHandler({ sortingType: "upload_date", value: 'old' }) }}>upload_date Old</a>
                                </li>
                                <li>
                                    <a onClick={() => { GalleryFilterHandler({ sortingType: "date_taken", value: 'new' }) }}>date_taken New</a>
                                </li>
                                <li>
                                    <a onClick={() => { GalleryFilterHandler({ sortingType: "date_taken", value: 'old' }) }}>date_taken Old</a>
                                </li>
                                <li>
                                    <a onClick={() => { GalleryFilterHandler({ sortingType: "random", value: 'old' }) }}>random</a>
                                </li>

                            </ul>
                        </ul>} */}

                    </>}
                </div>


                {isViewGallery.mainGallery &&
                    <MainGallery OtherUserGAllery={OtherUserGAllery} usertoken={usertoken} ReloadGalleryPhotolist={ReloadGalleryPhotolist} OtherUserID={OtherUserID} UserName={UserName} CheckPhotoListIsEmptyFn={CheckPhotoListIsEmptyFn} UserPaymentStatus={UserPaymentStatus} OtherUserPaymentStatus={OtherUserPaymentStatus} />
                }
            </div >

            {isViewGallery.manageGallery && !OtherUserGAllery && <ManageGallery usertoken={usertoken} CheckDelBtnIsActiveFn={CheckDelBtnIsActive} IsDeleteApiRun={IsDeleteApiRun} setIsDeleteApiRunFalse={setIsDeleteApiRunFalse} UserPaymentStatus={UserPaymentStatus} />
            }

            {
                GalleryUploadComponentState.open && !OtherUserGAllery && <GalleyPhotoUpload
                    usertoken={usertoken} CheckPhotoListIsEmptyFn={CheckPhotoListIsEmptyFn}
                />
            }


















            {IsDeleteGalleryPhotoModal && <>
                <div className="backdrop" />
                {/* Confirm Removal */}
                <div className="modal fade bph-modal show" id="QueuedPhotoModal" tabIndex={-1} aria-labelledby="sharePhotoModal" aria-hidden="true" style={{ display: "block" }}>
                    <div className="modal-dialog modal-dialog-centered modalAnimation">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* First */}
                                <div className="onboarding_modal photoUpload_modal photoQueued">
                                    <div className="modal_inner">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setIsDeleteGalleryPhotoModal(false) }} />
                                        {/* logo */}
                                        <div className="modal_logo d-none d-md-inline-flex">
                                            <img src="./assets/img/logo/modal-logo.png" alt='img' />
                                        </div>
                                        {/* content */}
                                        <div className="modal_content photoQueue_content removed">
                                            <div className="modal_title">
                                                <h3 className="title"><img src="./assets/img/icons/remove.svg" alt='img' />Remove</h3>
                                                <p className="subtitle">Are you sure you want to delete this photo(s) from your Other Work Gallery?</p>
                                                <button className="btn" type="submit" onClick={() => { setIsDeleteGalleryPhotoModal(false) }}>No, Don’t Delete</button>
                                                {!Loader ? <span className="btn_note" style={{ cursor: "pointer" }} onClick={DelHandler}>Yes, delete this photo(s).</span>
                                                    :
                                                    <span className="loader btn_note" style={{ borderTop: "solid black", margin: "10px auto" }}></span>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* !Confirm Removal */}
            </>
            }

        </>
    )
}

export default GalleryContainer
