import React from 'react'
import { ShortAddress } from '../../utils/Address';
import { useState, useEffect } from 'react';
import { followUser } from '../../utils/API/FollowApi';
import FollowerPageUserInfoSekeleton from '../Skeleton/FollowerPageUserInfoSekeleton';
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';

const UserInfo = (props) => {
    let [Isfollow, setIsfollow] = useState(props.isfollow)


    useEffect(() => {
        setIsfollow(props.isfollow);
    }, [props.isfollow]);


    let FirstName = props.UserName
    if (props.UserName && FirstName.includes(' ')) {
        FirstName = FirstName.split(' ')[0];
    } else {
        FirstName = props.UserName
    }


    const genresString = props.genresList ? Object.values(props.genresList).slice(0, 3).join(", ") : "";

    ///address
    let address
    if (!props.myProfile && props.UserLocation) {
        address = ShortAddress(props.UserLocation, 16);
    }

    // < --------Socket ------->
    let dispatch = useDispatch()

    let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

    let SendNotificationFn = () => {
        dispatch(SocketAction.setSocketReceiverUserID(props.UserId))
        dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
    }
    // <-------------------------------------------->


    let FollowHandler = async (event) => {
        setIsfollow(!Isfollow)
        try {

            await followUser(props.UserId, !Isfollow, props.usertoken, props.LoginUserId, SendNotificationFn);
        } catch (error) {

        }

    }


    return (
        <>
            {!props.myProfile ? <div className="pt-80 pb-40" style={{ paddingTop: "80px", paddingBottom: '40px' }}>
                {FirstName ? <div className="container">
                    <div className="profile-wrapper">
                        <div className="profile-left">
                            <div className="profile-thumb"
                                style={{
                                    backgroundImage: `url(${props.UserPhoto || ""})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "50%",
                                    cursor: "pointer",

                                }}>
                                {!props.UserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.UserName}&size=40&rounded=true&background=01655a&color=fff`} alt="img" />}
                            </div>
                            <div className="profile-content">
                                <h4 className="title ">Who's Following {FirstName}?</h4>
                                <p className="font--md  text-title mb-0">{props.UserName}</p>
                                <p className="font--sm  text-body">{address}</p>
                                <ul className="font--md ">
                                    {props.cameraBrand && props.cameraBrand !== "null" && <li>
                                        <span className="text-title">Camera:</span>
                                        <span className="text-body">{props.cameraBrand}</span>
                                    </li>}
                                    {genresString && <li>
                                        <span className="text-title">Genres:</span>
                                        <span className="text-body">{genresString}</span>
                                    </li>}
                                </ul>
                                <button className="btn btn--base btn--180 mt-3 d-md-none">Follow</button>
                            </div>
                        </div>
                        <div className="profile-right">
                            {!Isfollow ?
                                <button className="btn btn--base btn--180 " onClick={FollowHandler} style={{ minWidth: "160px" }}>Follow</button>
                                :
                                <button className="btn btn--base btn--unfollow btn--180 " onClick={FollowHandler} style={{ minWidth: "160px" }}>Following</button>}
                        </div>
                    </div>
                </div>:
                <FollowerPageUserInfoSekeleton/>}
            </div>
                :
                (
                    // <div className="pt-80 pb-40" style={!props.MarginTopNone ? { paddingTop: "100px", paddingBottom: "40px" } :
                    //     { paddingBottom: "40px" }}>
                    //     <div className="container">
                    //         <div className="profile-wrapper my-profile">
                    //             <div className="profile-thumb"
                    //                 style={{
                    //                     backgroundImage: `url(${props.loginUserPhoto || ""})`,
                    //                     backgroundPosition: "center",
                    //                     backgroundSize: "cover",
                    //                     backgroundRepeat: "no-repeat",
                    //                     borderRadius: "50%",
                    //                     cursor: "pointer",

                    //                 }}>
                    //                 {!props.loginUserPhoto && <img src={`https://ui-avatars.com/api/?name=${props.loginUserName}&size=40&rounded=true&background=01655a&color=fff`} alt="img" />}
                    //             </div>
                    //             <div className="profile-content">
                    //                 <h4 className="title " style={{ fontSize: "24px" }}>Your People, {props.loginUserName.split(' ')[0]}</h4>
                    //                 <p className="font--md ">Follow members you don’t want to lose track of, you find inspiring or you just want to stay connected with as we continue to grow. </p>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <section className="followers_page_header">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h4 className="title">Your People, {props.loginUserName.split(' ')[0]}</h4>
                                    <div className="desc">
                                        <p>Use this page to follow members you don't want to lose track of, you find inspiring or who you just
                                            want to connect with as we continue to grow.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                )}

        </>
    )
}

export default UserInfo