import React from 'react'
import UserData from './UserData'

import { useState } from 'react'
import { performVote } from '../../utils/API/UpVoteApi'
import { useEffect } from 'react'
import { SocketAction } from '../../Store/SocketSlice';
import { useSelector, useDispatch } from 'react-redux';

const SomeoneYouFollowPostedAPhoto = ({ NotificationData, usertoken }) => {
  let [upDownvote, setupDownvote] = useState(NotificationData.is_photo_upvoted)
  let [votebtnactive, setvotebtnactive] = useState(NotificationData.is_photo_upvoted)
  let [HeartAnimation, setHeartAnimation] = useState(false)
  let [IsMobile, setIsMobile] = useState(false)

  // < --------Socket ------->
  let dispatch = useDispatch()

  let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

  let SendNotificationFn = () => {
    dispatch(SocketAction.setSocketReceiverUserID(NotificationData.sender_id))
    dispatch(SocketAction.setReloadNotificationSenderFn(!ReloadNotificationFn))
  }
  // <-------------------------------------------->


  useEffect(() => {
    if (window.innerWidth <= 500) {
      setIsMobile(true)
    }
  }, [])

  let voteHandler = async (event) => {

    event.preventDefault();
    setvotebtnactive(!votebtnactive)
    setupDownvote(!upDownvote)

    let up_or_down_vote;
    if (upDownvote) {
      up_or_down_vote = "remove"
      setHeartAnimation(false)
    } else {
      up_or_down_vote = "add"
      setHeartAnimation(true)
      SendNotificationFn()
    }

    try {

      await performVote(NotificationData.photo_id, up_or_down_vote, usertoken);



    } catch (error) {

    }

  }
  return (
    <>
      <UserData NotificationData={NotificationData} message={NotificationData.text} ChildAfterUl={true}>
        <div className="photo-wrapper overflow-hidden">
          <img src={!IsMobile ? NotificationData.photo_url : `https://res.cloudinary.com/duk09hrua/image/upload/c_fit,w_700,f_auto,q_auto/Resized_Photos/${NotificationData.compressedImageId}`} alt="thumb" className="w-100 rounded-1" />
          <div className="bubble-animation-wrapper">
            <ul className={`bubbles ${HeartAnimation ? 'active' : ''}`}>
              <li><img src="assets/img/icons/heart-fill.svg" alt="heart-fill" /></li>
              <li><img src="assets/img/icons/heart-fill.svg" alt="heart-fill" /></li>
              <li><img src="assets/img/icons/heart-fill.svg" alt="heart-fill" /></li>
              <li><img src="assets/img/icons/heart-fill.svg" alt="heart-fill" /></li>

            </ul>
            <button onClick={voteHandler} className={`btn btn--success upvote--downvote btn--116 animateBubble ${votebtnactive ? "Upvoted active" : ''}`} data-normal="Upvote" data-active="Upvoted" >
              <svg width={13} height={8} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7L7 1L13 7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="text">{votebtnactive ? "Upvoted" : "Upvote"}</span>
            </button>
          </div>
        </div>
      </UserData>



    </>
  )
}

export default SomeoneYouFollowPostedAPhoto