import axios from "axios";

export const loadQueuedList = async (usertoken) => {
  try {
    const headers = {
      Authorization: `Bearer ${usertoken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/view_queued_photo`, {}, { headers });
    return response;
  } catch (error) {

    throw error;
  }
};




export const removeQueuedPhoto = async (usertoken, photoID) => {
  try {
    const payload = { photoID };
    const headers = {
      Authorization: `Bearer ${usertoken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/remove_queued_photo`, payload, { headers });
    return response;
  } catch (error) {
    console.error("Error removing queued photo:", error);
    throw error;
  }
};




export const editQueuedPhoto = async (usertoken, updatedValue) => {
  console.log("updatedValue", updatedValue);
  try {
    let payload = updatedValue;

    // if (targetbtn === "description") {
    //   payload.description = value.trim();
    // }
    // if (targetbtn === "genres_list") {
    //   payload.genres_list = value.join(',');
    // }
    // if (targetbtn === "feedback_type") {
    //   payload.feedback_type = value.join(',');
    // }
    // if (targetbtn === "day") {
    //   payload.day = value.join(',');
    // }

    const headers = {
      Authorization: `Bearer ${usertoken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/edit_photo`, payload, { headers });
    return response.data;
  } catch (error) {
    console.error("Error editing photo:", error);
    throw error;
  }
};



export const SortQueuedPhoto = async (usertoken, photoArray) => {
  try {
    const payload = { "photos": photoArray };
    const headers = {
      Authorization: `Bearer ${usertoken}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/photo/sort_queued_photo`, payload, { headers });
    return response;
  } catch (error) {
    console.error("Error removing queued photo:", error);
    throw error;
  }
};